<template>
    <div class="block block--incident_detail block--actions" :class="{watched: watchers.length}" >
        <span class="watch-label" v-if="watchers.length" v-tooltip.right="profile.dashboard.watched">
            <span><i class="fas fa-eye"></i> {{watchers}}</span>
        </span>
        <div class="row">
            <div class="col">
                <a class="back-link" @click="$router.go(-1)" style="margin: -10px 0 0 0; display: none;"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                <span v-if="incidentInfo.serviced_by_bm_user_id && (readyToFinish || newIncident || readyForTakeover || readyForRestart)" class="current-userlabel" v-tooltip.right="profile.incident_detail.incident_who + ' ' + incidentInfo.serviced_by_bm_user_first_name"><i class="fas fa-user"></i> {{incidentInfo.serviced_by_bm_user_first_name}}</span>
                <span v-if="!incidentInfo.serviced_by_bm_user_id" class="current-userlabel"><i class="fas fa-user-slash"></i> {{profile.incident_detail.not_in_use}}</span>
                <span v-if="incidentInfo.team_id_team" class="current-userlabel"><i class="fas fa-users"></i> {{incidentInfo.team_name}}</span>
            </div>
        </div>
        <div class="incident-head" v-if="incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON' && incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON_REMINDED'">
            <h1 style="font-size: 21px;">{{profile.incident_detail.available_actions}}</h1>
        </div>
        <div class="actions" v-if="incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON' && incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON_REMINDED'">
            <div class="main-buttons">
                <span class="button button--cta button--icon" data-testid="buttonReopenIncident" @click="restartIncidentModalOpen = true" v-if="readyForRestart" v-tooltip.right="profile.button_tooltips.restart_incident"><i class="fas fa-play"></i>{{profile.incident_detail.reactivate_task}}</span>
                <span class="button button--cta button--icon" data-testid="buttonStartIncident" @click="addHandler()" v-if="newIncident" v-tooltip.right="profile.button_tooltips.start_incident"><i class="fas fa-play"></i>{{profile.incident_detail.activate_task}}</span>
                <span class="button button--cta button--icon" data-testid="buttonTakeOverIncident" @click="takeOverModalOpen = true" v-if="readyForTakeover" v-tooltip.right="profile.button_tooltips.takeover_incident"><i class="fas fa-portrait"></i>{{profile.incident_detail.take_over_incident}}</span>
                <span class="button button--cta button--icon" data-testid="buttonCommissionIncident" @click="startCommissioning()" v-if="(!incidentInfo.has_order && !readOnly && incidentInfo.serviced_by_bm_user_id === incidentInfo.current_user_id) && !commissionStart && incidentInfo.incident_status !== 'fixed_agreed_auto' && incidentInfo.incident_status !== 'returned_to_buildingmanager'" v-tooltip.right="profile.button_tooltips.commission_incident"><i class="fas fa-thumbs-up"></i>{{profile.invoicing.submit}}</span>
                <span class="button button--cta button--icon" @click="restartCommissioning()" v-if="(incidentInfo.incident_status === 'returned_to_buildingmanager' || incidentInfo.incident_status === 'fixed_agreed_repaircompany' || incidentInfo.incident_status === 'fixed_agreed_auto') && !hideRestart && !incidentInfo.autodispatch && !readOnly" v-tooltip.right="profile.button_tooltips.commission_incident"><i class="fas fa-thumbs-up"></i>{{profile.invoicing.re_submit}}</span>
                <div class="extra-buttons" v-if="incidentInfo.wants_copy && !readOnly">
                    <span class="button button--cta button--icon" data-testid="buttonFollowUpIncident" @click="followUpAppointmentModalOpen = true" v-tooltip.right="profile.button_tooltips.followup"><i class="fas fa-calendar-plus"></i>{{profile.incident_detail.followup_button}}</span>
                </div>
                <span class="button button--cta button--icon" data-testid="buttonFinishIncident" @click="finishIncidentModalOpen = true" v-if="readyToFinish" v-tooltip.right="profile.button_tooltips.approve_incident"><i class="fas fa-handshake"></i>{{profile.incident_detail.agree_and_close}}</span>
            </div>
        </div>
        <div class="actions" v-else>
            <span class="button button--cta button--icon" data-testid="buttonStopAgreement" @click="removeIncidentAgreementModalOpen = true"><i class="fas fa-ban"></i>{{profile.owners.agreementflow.remove_agreement_flow}}</span>
        </div>
        <div class="sub-buttons-wrap" v-if="incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON' && incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON_REMINDED'" v-click-outside="closeSubButtons">
            <span class="sub-button-dropdown" data-testid="buttonOtherActions" :class="{active: subButtonsToggled}" @click="subButtonsToggled = !subButtonsToggled">{{profile.incident_detail.other_actions}} <i class="fa fa-chevron-down"></i></span>
            <div class="sub-buttons" :class="{active: subButtonsToggled}" v-if="subButtonsToggled">
                <span class="button button--small button--icon" @click="assignColleagueModalOpen = true" v-if="incidentInfo.incident_status !== 'cancelled' && incidentInfo.incident_status !== 'closed' && !readOnly"><i class="fas fa-user"></i>{{profile.colleagues.assign_colleague}}</span>
                <span class="button button--small button--icon" data-testid="assignTeamIncident" @click="assignTeamModalOpen = true" v-if="incidentInfo.incident_status !== 'cancelled' && incidentInfo.incident_status !== 'closed'" v-tooltip.right="profile.teams.assign_team_tooltip"><i class="fas fa-users"></i>{{profile.teams.assign_team}}</span>
                <span v-if="incidentInfo.has_order" class="button button--small button--icon" @click="uploadQuotationModalOpen = true"><i class="fas fa-file-pdf"></i>{{profile.invoicing.add_quotation}}</span>
                <span v-if="incidentInfo.has_order" class="button button--small button--icon" @click="uploadInvoiceModalOpen = true"><i class="fas fa-file-pdf"></i>{{profile.invoicing.add_invoice}}</span>
                <div v-if="(incidentInfo.can_copy_incidents && !readOnly) || (incidentInfo.serviced_by_bm_user_id && admin)">
                    <span class="button button--small button--icon" data-testid="buttonConnectIncident" @click="connectedIncidentModalOpen = true" v-tooltip.right="profile.button_tooltips.split_incident"><i class="far fa-copy"></i>{{profile.incident_detail.incident_copy_button}}</span>
                </div>
                <div v-if="(incidentInfo.repair_company_only_email || incidentInfo.repair_company_no_communication) &&
                                                 incidentInfo.incident_status !=='planned'
                                                 && incidentInfo.incident_status !=='cancelled'
                                             && incidentInfo.incident_status !=='paused_mechanic'
                                                 && incidentInfo.incident_status !=='on_location_mechanic'
                                                 && incidentInfo.incident_status !=='closed'
                                                 && incidentInfo.incident_status !=='fixed_mechanic'
                                                 && incidentInfo.canbe_id_repair_company !== incidentInfo.id_repair_company && !readOnly">
                    <span class="button button--small button--neutral button--icon" data-testid="buttonPlanRepair" @click="planRepairModalOpen = true"><i class="fas fa-calendar-day" style="margin-right:5px;" v-tooltip.right="profile.button_tooltips.plan_incident"></i>{{profile.incident_detail.plan_mechanic_repair}}</span>
                </div>
                <div v-if="incidentInfo.repair_company_only_email && incidentInfo.incident_status ==='planned' && incidentInfo.canbe_id_repair_company !== incidentInfo.id_repair_company">
                    <span class="button button--small button--neutral button--icon" data-testid="buttonCancelRepair" @click="cancelRepairModalOpen = true" v-tooltip.right="profile.button_tooltips.cancel_appointment"><i class="fas fa-ban"></i>{{profile.incident_detail.cancel_mechanic_repair}}</span>
                </div>
                <div v-if="manualCheckIn || manualCheckOut || manualCheckInOut" class="extra-buttons">
                    <span class="button button--small button--neutral button--icon" data-testid="buttonManualCheckIn" @click="manualCheckinModalOpen = true" v-if="manualCheckIn" v-tooltip.right="profile.button_tooltips.checkin_specialist"><i class="fas fa-play"></i>{{profile.incident_detail.on_location_mechanic}}</span>
                    <span class="button button--small button--neutral button--icon" data-testid="buttonManualPause" @click="manualPauseModalOpen = true" v-if="manualCheckOut" v-tooltip.right="profile.button_tooltips.pause_specialist"><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic}}</span>
                    <span class="button button--small button--neutral button--icon" data-testid="buttonManualCheckOut" @click="manualCheckoutModalOpen = true" v-if="manualCheckOut" v-tooltip.right="profile.button_tooltips.checkout_specialist"><i class="fas fa-stop"></i>{{profile.incident_detail.fixed_mechanic}}</span>
                    <span class="button button--small button--neutral button--icon" data-testid="buttonManualCheckInOut" @click="manualCheckInOutModalOpen = true" v-if="manualCheckInOut" v-tooltip.right="profile.button_tooltips.checkinout_specialist"><i class="fas fa-toolbox"></i>{{profile.incident_detail.checkout_mechanic_repair}}</span>
                </div>
                <span class="button button--small button--icon" data-testid="buttonPassIncident" @click="passIncidentModalOpen = true" v-if="(incidentInfo.incident_status === 'new' || incidentInfo.incident_status === 'pending_buildingmanager' || incidentInfo.incident_status === 'returned_to_buildingmanager') && !readOnly" v-tooltip.right="profile.button_tooltips.forward_incident"><i class="far fa-hand-point-right"></i>{{profile.incident_detail.pass_incident}}</span>
                <span class="button button--small button--icon" data-testid="buttonCancelIncident" @click="cancelIncidentModalOpen = true" v-if="readyToFinish && incidentInfo.incident_status !== 'cancelled'" v-tooltip.right="profile.button_tooltips.cancel_incident"><i class="fas fa-ban"></i>{{profile.incident_detail.cancel_incident}}</span>
                <span class="button button--small button--icon" data-testid="buttonParkOpen" @click="parkIncidentModalOpen = true" v-if="incidentInfo.incident_status !== 'new' && (incidentInfo.serviced_by_bm_user_id && incidentInfo.serviced_by_bm_user_id === incidentInfo.current_user_id) && !incidentInfo.service_desk_suspended_until" v-tooltip.right="profile.incident_detail.park_incident_tooltip"><i class="fas fa-parking"></i>{{profile.incident_detail.park_incident}}</span>
                <span class="button button--small button--icon" data-testid="buttonParkClose" @click="unparkIncidentModalOpen = true" v-if="incidentInfo.service_desk_suspended_until"><i class="fas fa-parking"></i>{{profile.incident_detail.remove_parking}}</span>
                <span class="button button--small button--icon" data-testid="buttonParkChange" @click="parkIncidentModalOpen = true" v-if="incidentInfo.service_desk_suspended_until"><i class="fas fa-parking"></i>{{profile.incident_detail.change_park}}</span>
                <span class="button button--small button--icon" data-testid="buttonMandateOverwrite" @click="mandateViolationModalOpen = true" v-if="incidentInfo.has_order && hasMandates && mandate.amount"><i class="fas fa-exclamation"></i>{{profile.mandates.mandate_violation}}</span>
            </div>
        </div>
        <div class="notification notification--error" style="clear: both; display: block; margin-top: 20px;" v-if="showWaldorfError">Autodispatch en serviceteam ingesteld op deze melding!</div>
        <CancelIncidentModal v-if="cancelIncidentModalOpen" @close="cancelIncidentModalOpen = false" :incidentId="incidentInfo.id_incident" />
        <FollowUpAppointmentModal v-if="followUpAppointmentModalOpen" @close="followUpAppointmentModalOpen = false" :incidentId="incidentInfo.id_incident" :incidentDescription="incidentInfo.incident_description" :categories="categories" />
        <ConnectedIncidentModal v-if="connectedIncidentModalOpen" @close="connectedIncidentModalOpen = false" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" :categories="categories" />
        <AssignColleagueModal v-if="assignColleagueModalOpen" @close="assignColleagueModalOpen = false" :incidentInfo="incidentInfo" @getIncidentInfo="getIncidentInfo()" />
        <AssignTeamModal v-if="assignTeamModalOpen" @close="assignTeamModalOpen = false" :incidentInfo="incidentInfo" @getIncidentInfo="getIncidentInfo()" />
        <RestartIncidentModal v-if="restartIncidentModalOpen" @close="restartIncidentModalOpen = false" @restartincident="restartIncident()" />
        <FinishIncidentModal v-if="finishIncidentModalOpen" @close="finishIncidentModalOpen = false" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" />
        <RefuseWorkModal v-if="refuseWorkModalOpen" @close="refuseWorkModalOpen = false" @refuse="getIncidentInfo()" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" />
        <TakeOverModal v-if="takeOverModalOpen" @close="takeOverModalOpen = false" @takeover="takeOverIncident()" :userName="incidentInfo.serviced_by_bm_user_first_name" :incidentInfo="incidentInfo" />
        <ManualCheckInModal v-if="manualCheckinModalOpen" @close="manualCheckinModalOpen = false" :incidentId="incidentInfo.id_incident" @checkin="getIncidentInfo()" />
        <ManualCheckOutModal v-if="manualCheckoutModalOpen" @close="manualCheckoutModalOpen = false" :incidentId="incidentInfo.id_incident" @checkout="getIncidentInfo()" />
        <ManualCheckInOutModal v-if="manualCheckInOutModalOpen" @close="manualCheckInOutModalOpen = false" :incidentId="incidentInfo.id_incident" :appointmentId="incidentInfo.id_appointment" @checkout="getIncidentInfo()"  />
        <ManualPauseModal v-if="manualPauseModalOpen" @close="manualPauseModalOpen = false" :incidentId="incidentInfo.id_incident" @pause="getIncidentInfo()" />
        <PlanRepairModal v-if="planRepairModalOpen" @close="planRepairModalOpen = false" :incidentId="incidentInfo.id_incident" :repairCompany="incidentInfo.id_repair_company" @planned="getIncidentInfo()" />
        <CancelRepairModal v-if="cancelRepairModalOpen" @close="cancelRepairModalOpen = false" :incidentId="incidentInfo.id_incident" :appointmentId="incidentInfo.id_appointment" @removed="getIncidentInfo()" />
        <PassIncidentModal v-if="passIncidentModalOpen" @close="passIncidentModalOpen = false" @success="getIncidentInfo()" :incidentId="incidentInfo.id_incident" :buildingmanagers="buildingmanagers" />
        <ParkIncidentModal v-if="parkIncidentModalOpen" @close="parkIncidentModalOpen = false" @success="getIncidentInfo()" :incidentInfo="incidentInfo" />
        <UnParkIncidentModal v-if="unparkIncidentModalOpen" @close="unparkIncidentModalOpen = false" @success="getIncidentInfo()" :incidentId="incidentInfo.id_incident" />
        <RemoveIncidentAgreementModal v-if="removeIncidentAgreementModalOpen" @close="removeIncidentAgreementModalOpen = false" :incidentId="incidentInfo.id_incident" @success="getIncidentInfo()" />
        <MandateViolationModal v-if="mandateViolationModalOpen" @close="mandateViolationModalOpen = false" @success="getIncidentInfo(true)" :mandate="mandate" :incidentInfo="incidentInfo" />
        <UploadInvoiceModal v-if="uploadInvoiceModalOpen" @close="uploadInvoiceModalOpen = false" @success="getIncidentInfo(true)" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" />
        <UploadQuotationModal v-if="uploadQuotationModalOpen" @close="uploadQuotationModalOpen = false" @success="getIncidentInfo(true)" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" />
   </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import AssignColleagueModal from "@/components/modals/AssignColleagueModal.vue";
import AssignTeamModal from "@/components/modals/AssignTeamModal.vue";
import RestartIncidentModal from "@/components/modals/RestartIncidentModal.vue";
import FinishIncidentModal from "@/components/modals/FinishIncidentModal.vue";
import RefuseWorkModal from "@/components/modals/RefuseWorkModal.vue";
import TakeOverModal from "@/components/modals/TakeOverModal.vue";
import ManualCheckInModal from "@/components/modals/ManualCheckInModal.vue";
import ManualCheckOutModal from "@/components/modals/ManualCheckOutModal.vue";
import ManualCheckInOutModal from "@/components/modals/ManualCheckInOutModal.vue";
import ManualPauseModal from "@/components/modals/ManualPauseModal.vue";
import PlanRepairModal from "@/components/modals/PlanRepairModal.vue";
import CancelRepairModal from "@/components/modals/CancelRepairModal.vue";
import PassIncidentModal from "@/components/modals/PassIncidentModal.vue";
import ConnectedIncidentModal from "@/components/modals/ConnectedIncidentModal.vue";
import CancelIncidentModal from "@/components/modals/CancelIncidentModal.vue";
import FollowUpAppointmentModal from "@/components/modals/FollowUpAppointmentModal.vue";
import ParkIncidentModal from "@/components/modals/ParkIncidentModal.vue";
import UnParkIncidentModal from "@/components/modals/UnParkIncidentModal.vue";
import RemoveIncidentAgreementModal from "@/components/modals/RemoveIncidentAgreementModal.vue";
import MandateViolationModal from "@/components/modals/MandateViolationModal.vue";
import UploadQuotationModal from "@/components/modals/UploadQuotationModal.vue";
import UploadInvoiceModal from "@/components/modals/UploadInvoiceModal.vue";

export default {
    name: 'Actions',
    mixins: [setLocale],
    components: {
        RestartIncidentModal,
        ConnectedIncidentModal,
        FinishIncidentModal,
        RefuseWorkModal,
        TakeOverModal,
        ManualCheckInModal,
        ManualCheckOutModal,
        ManualCheckInOutModal,
        ManualPauseModal,
        PlanRepairModal,
        CancelRepairModal,
        PassIncidentModal,
        AssignColleagueModal,
        AssignTeamModal,
        CancelIncidentModal,
        FollowUpAppointmentModal,
        ParkIncidentModal,
        UnParkIncidentModal,
        RemoveIncidentAgreementModal,
        MandateViolationModal,
        UploadQuotationModal,
        UploadInvoiceModal
    },
    data: function () {
        return {
            refNumberEdit: false,
            subButtonsToggled: false,
            restartIncidentModalOpen: false,
            finishIncidentModalOpen: false,
            refuseWorkModalOpen: false,
            takeOverModalOpen: false,
            manualCheckinModalOpen: false,
            manualCheckoutModalOpen: false,
            manualCheckInOutModalOpen: false,
            manualPauseModalOpen: false,
            planRepairModalOpen: false,
            cancelIncidentModalOpen: false,
            cancelRepairModalOpen: false,
            passIncidentModalOpen: false,
            assignTeamModalOpen: false,
            assignColleagueModalOpen: false,
            connectedIncidentModalOpen: false,
            followUpAppointmentModalOpen: false,
            parkIncidentModalOpen: false,
            unparkIncidentModalOpen: false,
            removeIncidentAgreementModalOpen: false,
            watchers: '',
            mandateViolationModalOpen: false,
            started: false,
            mandate: null,
            uploadInvoiceModalOpen: false,
            uploadQuotationModalOpen: false,
            showWaldorfError: false
        }
    },
    props: [ 'incidentInfo',
        'admin',
        'hasMandates',
        'readOnly',
        'forwardedReadOnly',
        'readyToFinish',
        'newIncident',
        'readyForTakeover',
        'readyForRestart',
        'manualCheckIn',
        'manualCheckOut',
        'manualCheckInOut',
        'noStatusChange',
        'categories',
        'buildingmanagers',
        'commissionStart',
        'actualName',
        'hideRestart'
    ],
    computed: mapState(['stickAction', 'incident', 'mandateAdded']),
    watch: {
        stickAction() {
            if (this.stickAction === 'restart') {
                this.restartIncidentModalOpen = true;
            }
            if (this.stickAction === 'start') {
                this.startIncident();
            }
            if (this.stickAction === 'takeover') {
                this.takeOverModalOpen = true;
            }
            if (this.stickAction === 'commission') {
                this.startCommissioning();
            }
            if (this.stickAction === 'recommission') {
                this.restartCommissioning();
            }
            if (this.stickAction === 'followup') {
                this.followUpAppointmentModalOpen = true;
            }
            if (this.stickAction === 'finishwork') {
                this.finishWork();
            }
            if (this.stickAction === 'finish') {
                this.finishIncidentModalOpen = true;
            }
        },
        incident() {
            setTimeout(function () {
                if (this.incident.id_incident.toString() === this.incidentInfo.id_incident.toString()) {
                    if (this.watchers && this.incident.user !== this.actualName && !this.incident.from) {
                        if (this.watchers !== this.incident.incident.user) {
                            this.watchers = this.watchers + ', ' + this.incident.user;
                        }
                    }
                    if (!this.watchers && this.incident.user !== this.actualName && !this.incident.from) {
                        this.watchers = this.incident.user;
                    }
                    if (this.watchers !== this.actualName && this.incident.from) {
                        this.watchers = '';
                    }
                }
            }.bind(this), 500);
        },
        mandateAdded() {
            this.getMandate();
        }
    },
    created() {
        this.getMandate();
        if (this.incidentInfo.watchers) {
            let allWatchers = this.incidentInfo.watchers.split(', ');
            for (var i = 0; i < allWatchers.length; i++) {
                let watcher = allWatchers[i];
                if (watcher === this.actualName) {
                    allWatchers.splice(i, 1);
                }
            }
            this.watchers = allWatchers.join();
        }
        if (this.incidentInfo.canbe_id_repair_company === this.incidentInfo.id_repair_company && this.incidentInfo.autodispatch && window.location.href.includes('waldorf')) {
            this.showWaldorfError = true;
        }
    },
    methods: {
        getMandate() {
            let self = this;
            fetch('/api/v1/incidentmandate/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let mandate = data[i];
                        if (mandate.active) {
                            self.mandate = mandate;
                        }
                        else {
                            self.mandate = {
                                amount: 0
                            }
                        }
                    }
                    if (!data.length) {
                        self.mandate = {
                            amount: 0
                        }
                    }
                    if (self.mandate && self.mandate.id_incident_mandate) {
                        if (self.mandate.amount.toString().includes('.')) {
                            self.mandate.amount = (Math.round(self.mandate.amount * 100) / 100).toFixed(2);
                        }
                    }
                });
            });
        },
        addHandler() {
            window.addEventListener('click', this.startIncident);
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Incident detail - Incident starten',
            });
        },
        removeHandler() {
            window.removeEventListener('click', this.startIncident);
        },
        startIncident() {
            if (!this.started) {
                this.started = true;
                this.startIncidentModalOpen = false;
                this.handleNewStatus();
                this.removeHandler();
            }
        },
        restartIncident() {
            this.handleNewStatus();
            let successText = this.profile.incident_detail.incident_start;
            this.$vToastify.success(successText, this.profile.toast.started);
        },
        handleNewStatus() {
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id');
            if (this.incidentInfo.id_incident.toString() === id) {
                let status;
                if (this.noStatusChange) {
                    status = this.incidentInfo.incident_status;
                }
                else {
                    status = 'pending_buildingmanager';
                }
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('message', '');
                data.append('status', status);
                let self = this;
                fetch('/api/v1/incident/changestatus', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    if (!self.noChangeStatus) {
                        self.incidentInfo.incident_status = 'pending_buildingmanager';
                        let successText = self.profile.incident_detail.incident_start;
                        self.$vToastify.success(successText, self.profile.toast.started);
                    }
                    self.getIncidentInfo();
                });
            }
        },
        takeOverIncident() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            let self = this;
            fetch('/api/v1/incident/changeserviceuser', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                let successText = self.profile.dashboard.building_manager.message_table.incident_ID +  ' <b>' + self.incidentInfo.id_incident + '</b> ' + self.profile.toast.taken_over_successfully;
                self.$vToastify.success(successText, self.profile.toast.incident_taken_over);
                self.getIncidentInfo();
            });
        },
        getIncidentInfo(getNotes) {
            if (getNotes) {
                this.$store.dispatch('updateGetNotes');
            }
            this.subButtonsToggled = false;
            this.refuseWorkModalOpen = false;
            this.parkIncidentModalOpen = false;
            this.unparkIncidentModalOpen = false;
            this.mandateViolationModalOpen = false;
            this.removeIncidentAgreementModalOpen = false;
            this.$emit('getIncidentInfo');
        },
        startCommissioning() {
            this.$emit('startCommissioning');
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Incident detail - Opdrachtverlening starten',
            });
        },
        restartCommissioning() {
            this.hideRestart = true;
            this.$emit('restartCommissioning');
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Incident detail - Opdrachtverlening opnieuw starten',
            });
        },
        finishWork() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('status', 'fixed_agreed_repaircompany');
            let self = this;
            fetch('/api/v1/incident/changestatus', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.getIncidentInfo();
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Opdracht afronden',
                });
            });
        },
        closeSubButtons() {
            this.subButtonsToggled = false;
        }
    }
}
</script>
