<template>
    <div class="main-content">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="block block--repaircompanies">
                        <section class="incident-head" style="margin-bottom: 20px; padding-bottom: 15px;">
                            <h1>{{ profile.nav.statistics }} van {{ managerName }}</h1>
                        </section>
                        <div class="link-switch" style="margin-bottom: 20px;">
                            <span :class="{active: switchType === '3M'}" @click="switchFrame('3M')">{{profile.datetime.three_months}}</span>
                            <span :class="{active: switchType === '6M'}" @click="switchFrame('6M')">{{profile.datetime.six_months}}</span>
                            <span :class="{active: switchType === '1Y'}" @click="switchFrame('1Y')">{{profile.datetime.one_year}}</span>
                            <span :class="{active: switchType === 'YTD'}" @click="switchFrame('YTD')">{{profile.datetime.this_year}}</span>
                            <span :class="{active: switchType === 'ALL'}" @click="switchFrame('ALL')">{{profile.datetime.all}}</span>
                        </div>
                        <span style="display: block; clear: both; margin-bottom: 10px; color: #8b8b8b;">{{ profile.datetime.click_dayview }}</span>
                        <Bar :data="chartData" :options="options" v-if="loaded" />
                        <LineChart :data="lineChartData" :options="lineOptions" v-if="loaded" />
                        <div class="loading" v-if="!loaded"><img src="@/assets/icons/loading.gif" /></div>
                        <Bar :data="dayChartData" :options="dayOptions" v-if="daysLoaded && showDays" style="margin-top:20px"/>
                        <div class="loading" v-if="!daysLoaded && showDays"><img src="@/assets/icons/loading.gif" /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js'
import { Bar, Line as LineChart } from 'vue-chartjs';
import { Colors } from 'chart.js';
ChartJS.register(Colors);
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend)

export default {
    mixins: [setLocale],
    components: {
        Bar,
        LineChart
    },
    data() {
        return {
            profile: {},
            managerId: "",
            managerName: "",
            managers: [],
            selectedLabel: null,
            selectedData: null,
            options: {
                responsive: true,
                events: ['click', 'mousemove'],
                onClick: (event, elements, chart) => {
                 if (elements[0]) {
                    const i = elements[0].index;
                    this.selectedLabel = chart.data.labels[i];
                    this.selectedData = chart.data.datasets[0].data[i];
                    this.getDayStatistics();
                 }
               },
               interaction: {
                    intersect: false,
               },
               scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                }
            },
            dayOptions: {
                responsive: true,
               interaction: {
                    intersect: false,
               },
               scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                }
            },
            lineOptions: {
            responsive: true,
            scales: {
                y: {
                    title: {
                        display: true,
                        text: 'In %'
                    },
                    max: 100, // Set the maximum value of the y-axis to 100
                    beginAtZero: true // Ensure the y-axis starts at 0
                }
            }
            },
            switchType: '6M',
            loaded: false,
            showDays: false,
            daysLoaded: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 0'
                    },
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 0'
                    },
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 1'
                    },
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 1'
                    }
                ]
            },
            dayChartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 0'
                    },
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 0'
                    },
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 1'
                    },
                    {
                        label: '',
                        data: [],
                        stack: 'Stack 1'
                    }
                ]
            },
            lineChartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        pointStyle: 'circle',
                        pointRadius: 5,
                        pointHoverRadius: 10,
                    }
                ]
            },
            allTimeStats: {
                datasets: [
                {
                        label: '',
                        data: [],
                    },
                    {
                        label: '',
                        data: [],
                    },
                    {
                        label: '',
                        data: [],
                    },
                    {
                        label: '',
                        data: [],
                    }
                ]
            },
        }
    },
    created() {
        this.managerId = this.$route.query.id;
        this.getAllTimeStats();
        this.loadManagers();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        loadManagers() {
            let self = this;
            fetch('/api/v1/buildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                    self.managerName = self.getManagerName(self.managerId);
                });
            });
        },
        getManagerName(id) {
            const manager = this.managers.find(m => m.id_building_manager === parseInt(id));
            return manager ? manager.name : '';
        },
        switchFrame(type) {
            this.showDays = false;
            this.daysLoaded = false;
            this.loaded = false;
            this.switchType = type;
            if (type === '3M') {
                this.getStatistics(this.getDateMinusNMonths(2), this.getCurrentDate(), 'month');
            }
            if (type === '6M') {
                this.getStatistics(this.getDateMinusNMonths(6), this.getCurrentDate(), 'month');
            }
            if (type === '1Y') {
                this.getStatistics(this.getDateMinusNMonths(12), this.getCurrentDate(), 'month');
            }
            if (type === 'YTD') {
                this.getStatistics(this.firstOfJanuary(), this.getCurrentDate(), 'month');
            }
            if (type === 'ALL') {
                this.getStatistics('01-01-2015', this.getCurrentDate(), 'month');
            }
        },
        getStatistics(start, end, timeunit) {
            let self = this;
            fetch('/api/v1/s1monestats/incidentsbybuildingmanagerpertime?id_building_manager=' + this.managerId + '&start=' + start  + '&end=' + end + '&time=' + timeunit)
            .then(response => { response.json().then(
                function(data) {
                    self.chartData.labels = data.incidents_new_dashboard.incidents_keys;
                    self.chartData.datasets[0].label = self.profile.datetime.new_onboarding;
                    self.chartData.datasets[0].data = data.incidents_new_onboarding.incidents_values;
                    self.chartData.datasets[1].label = self.profile.datetime.new_dashboard;
                    self.chartData.datasets[1].data = data.incidents_new_dashboard.incidents_values;
                    self.chartData.datasets[2].label = self.profile.datetime.closed_dashboard;
                    self.chartData.datasets[2].data = data.incidents_closed_dashboard.incidents_values;
                    self.chartData.datasets[3].label = self.profile.datetime.closed_onboarding;
                    self.chartData.datasets[3].data = data.incidents_closed_onboarding.incidents_values;
                    self.loaded = true;

                    self.lineChartData.labels = data.incidents_new_dashboard.incidents_keys;
                    self.getLineStatistics(self.switchType);
                });
            });
        },
        getAllTimeStats() {
            let self = this;
            fetch('/api/v1/s1monestats/incidentsbybuildingmanagerpertime?id_building_manager=' + this.managerId + '&start=01-01-2015&end=' + this.getCurrentDate() + '&time=month')
            .then(response => { response.json().then(
                function(data) {
                    self.allTimeStats.datasets[0].label = self.profile.datetime.new_onboarding;
                    self.allTimeStats.datasets[0].data = data.incidents_new_onboarding.incidents_values;
                    self.allTimeStats.datasets[1].label = self.profile.datetime.new_dashboard;
                    self.allTimeStats.datasets[1].data = data.incidents_new_dashboard.incidents_values;
                    self.allTimeStats.datasets[2].label = self.profile.datetime.closed_dashboard;
                    self.allTimeStats.datasets[2].data = data.incidents_closed_dashboard.incidents_values;
                    self.allTimeStats.datasets[3].label = self.profile.datetime.closed_onboarding;
                    self.allTimeStats.datasets[3].data = data.incidents_closed_onboarding.incidents_values;

                    self.getStatistics(self.getDateMinusNMonths(6), self.getCurrentDate(), 'month');
                });
            });
        },
        getLineStatistics(type){
            this.lineChartData.datasets[0].label = "Percentage Qr-code meldingen";
            this.lineChartData.datasets[0].data = this.allTimeStats.datasets[0].data.map((value, index) => {
                let qrIncidents = 0;
                let allIncidents = 0;
                for (let i = 0; i <= index; i++) {
                    qrIncidents += this.allTimeStats.datasets[0].data[i];
                    allIncidents += this.allTimeStats.datasets[0].data[i] + this.allTimeStats.datasets[1].data[i];
                }
                const percentage = allIncidents === 0 ? 0 : qrIncidents / allIncidents * 100;
                return Math.round(percentage);
            });
            if (type === '3M') {
                this.lineChartData.datasets[0].data = this.lineChartData.datasets[0].data.slice(-3);
            } else if (type === '6M') {
                this.lineChartData.datasets[0].data = this.lineChartData.datasets[0].data.slice(-7);
            } else if (type === '1Y') {
                this.lineChartData.datasets[0].data = this.lineChartData.datasets[0].data.slice(-13);
            } else if (type === 'YTD') {
                const currentMonth = new Date().getMonth() + 1;
                this.lineChartData.datasets[0].data = this.lineChartData.datasets[0].data.slice(-currentMonth);
            }
        },
        getDayStatistics() {
            this.showDays = true;
            this.daysLoaded = false;
            let start;
            if (this.selectedLabel.toLowerCase().includes('jan.')) {
                start = '01-01-' + this.selectedLabel.toLowerCase().split('jan.');
            }
            if (this.selectedLabel.toLowerCase().includes('feb.')) {
                start = '01-02-' + this.selectedLabel.toLowerCase().split('feb.');
            }
            if (this.selectedLabel.toLowerCase().includes('mrt.')) {
                start = '01-03-' + this.selectedLabel.toLowerCase().split('mrt.');
            }
            if (this.selectedLabel.toLowerCase().includes('apr.')) {
                start = '01-04-' + this.selectedLabel.toLowerCase().split('apr.');
            }
            if (this.selectedLabel.toLowerCase().includes('mei')) {
                start = '01-05-' + this.selectedLabel.toLowerCase().split('mei');
            }
            if (this.selectedLabel.toLowerCase().includes('jun.')) {
                start = '01-06-' + this.selectedLabel.toLowerCase().split('jun.');
            }
            if (this.selectedLabel.toLowerCase().includes('jul.')) {
                start = '01-07-' + this.selectedLabel.toLowerCase().split('jul.');
            }
            if (this.selectedLabel.toLowerCase().includes('aug.')) {
                start = '01-08-' + this.selectedLabel.toLowerCase().split('aug.');
            }
            if (this.selectedLabel.toLowerCase().includes('sep.')) {
                start = '01-09-' + this.selectedLabel.toLowerCase().split('sep.');
            }
            if (this.selectedLabel.toLowerCase().includes('okt.')) {
                start = '01-10-' + this.selectedLabel.toLowerCase().split('okt.');
            }
            if (this.selectedLabel.toLowerCase().includes('nov.')) {
                start = '01-11-' + this.selectedLabel.toLowerCase().split('nov.');
            }
            if (this.selectedLabel.toLowerCase().includes('dec.')) {
                start = '01-12-' + this.selectedLabel.toLowerCase().split('dec.');
            }
            if (this.selectedLabel.toLowerCase().includes('jan')) {
                start = '01-01-' + this.selectedLabel.toLowerCase().split('jan');
            }
            if (this.selectedLabel.toLowerCase().includes('feb')) {
                start = '01-02-' + this.selectedLabel.toLowerCase().split('feb');
            }
            if (this.selectedLabel.toLowerCase().includes('mar')) {
                start = '01-03-' + this.selectedLabel.toLowerCase().split('mar');
            }
            if (this.selectedLabel.toLowerCase().includes('apr')) {
                start = '01-04-' + this.selectedLabel.toLowerCase().split('apr');
            }
            if (this.selectedLabel.toLowerCase().includes('may')) {
                start = '01-05-' + this.selectedLabel.toLowerCase().split('may');
            }
            if (this.selectedLabel.toLowerCase().includes('jun')) {
                start = '01-06-' + this.selectedLabel.toLowerCase().split('jun');
            }
            if (this.selectedLabel.toLowerCase().includes('jul')) {
                start = '01-07-' + this.selectedLabel.toLowerCase().split('jul');
            }
            if (this.selectedLabel.toLowerCase().includes('aug')) {
                start = '01-08-' + this.selectedLabel.toLowerCase().split('aug');
            }
            if (this.selectedLabel.toLowerCase().includes('sep')) {
                start = '01-09-' + this.selectedLabel.toLowerCase().split('sep');
            }
            if (this.selectedLabel.toLowerCase().includes('oct')) {
                start = '01-10-' + this.selectedLabel.toLowerCase().split('oct');
            }
            if (this.selectedLabel.toLowerCase().includes('nov')) {
                start = '01-11-' + this.selectedLabel.toLowerCase().split('nov');
            }
            if (this.selectedLabel.toLowerCase().includes('dec')) {
                start = '01-12-' + this.selectedLabel.toLowerCase().split('dec');
            }
            start = start.replace(',', '');
            start = start.replace('.', '');
            start = start.replace(' ', '');
            let end = start.split('-')[0] + '-' + start.split('-')[1] + '-' + start.split('-')[2];
            let month = parseInt(start.split('-')[1]);
            if (month === 4 || month === 6 || month === 9 || month === 11) {
                end = end.replace('01', '30');
            } else if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) {
                end = end.replace('01', '31');
            }
            let self = this;
            fetch('/api/v1/s1monestats/incidentsbybuildingmanagerpertime?id_building_manager=' + this.managerId + '&start=' + start  + '&end=' + end + '&time=day')
            .then(response => { response.json().then(
                function(data) {
                   self.dayChartData.labels = data.incidents_new_onboarding.incidents_keys;
                   self.dayChartData.datasets[0].label = self.profile.datetime.new_onboarding;
                   self.dayChartData.datasets[0].data = data.incidents_new_onboarding.incidents_values;
                   self.dayChartData.datasets[1].label = self.profile.datetime.new_dashboard;
                   self.dayChartData.datasets[1].data = data.incidents_new_dashboard.incidents_values;
                   self.dayChartData.datasets[2].label = self.profile.datetime.closed_dashboard;
                   self.dayChartData.datasets[2].data = data.incidents_closed_dashboard.incidents_values;
                   self.dayChartData.datasets[3].label = self.profile.datetime.closed_onboarding;
                   self.dayChartData.datasets[3].data = data.incidents_closed_onboarding.incidents_values;
                   self.daysLoaded = true;
                });
            });
        },
        getDateMinusNMonths(n) {
            let today = new Date();
            today.setMonth(today.getMonth() - n);
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            let yyyy = today.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
        getCurrentDate() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            let yyyy = today.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
        firstOfJanuary() {
            let today = new Date();
            let yyyy = today.getFullYear();
            let januaryFirst = new Date(yyyy, 0, 1); // Month is 0-indexed, so 0 is January
            let dd = String(januaryFirst.getDate()).padStart(2, '0');
            let mm = String(januaryFirst.getMonth() + 1).padStart(2, '0'); // January is 0, so +1 to get 01
            return dd + '-' + mm + '-' + yyyy;
        }
    }
}
</script>
