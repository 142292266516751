<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.incident_detail.checkout_mechanic_repair}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="manualCheckInOut()">
                    <div class="row">
                        <div class="col-sm-3">
                            <p style="display: block; margin-top: 13px;"><b>{{profile.incident_detail.checkout_mechanic_repair_checkin_time}}</b></p>
                        </div>
                        <div class="col-sm-2">
                            <select class="form-select" data-testid="checkInDay" v-model="fromFields.day" required>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div class="col-sm-2">
                            <select class="form-select" data-testid="checkInMonth" v-model="fromFields.month" required>
                                <option value="1">{{profile.months_short.jan}}</option>
                                <option value="2">{{profile.months_short.feb}}</option>
                                <option value="3">{{profile.months_short.mar}}</option>
                                <option value="4">{{profile.months_short.apr}}</option>
                                <option value="5">{{profile.months_short.may}}</option>
                                <option value="6">{{profile.months_short.jun}}</option>
                                <option value="7">{{profile.months_short.jul}}</option>
                                <option value="8">{{profile.months_short.aug}}</option>
                                <option value="9">{{profile.months_short.sep}}</option>
                                <option value="10">{{profile.months_short.oct}}</option>
                                <option value="11">{{profile.months_short.nov}}</option>
                                <option value="12">{{profile.months_short.dec}}</option>
                            </select>
                        </div>
                        <div class="col-sm-2">
                            <select class="form-select" data-testid="checkInYear" v-model="fromFields.year" required>
                                <option data-testid="checkInYearResult" v-for="(year, index) in years" :key="index" :value="year" :selected="year === 2018">{{year}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <flat-pickr v-model="fromFields.time" data-testid="checkInMinute" class="form-control" :config="flatPickrTimeConfig"></flat-pickr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <p style="display: block; margin-top: 13px;"><b>{{profile.incident_detail.checkout_mechanic_repair_checkout_time}}</b></p>
                        </div>
                        <div class="col-sm-2">
                            <select class="form-select" data-testid="checkOutDay" v-model="toFields.day" required>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div class="col-sm-2">
                            <select class="form-select" data-testid="checkOutMonth" v-model="toFields.month" required>
                                <option value="1">{{profile.months_short.jan}}</option>
                                <option value="2">{{profile.months_short.feb}}</option>
                                <option value="3">{{profile.months_short.mar}}</option>
                                <option value="4">{{profile.months_short.apr}}</option>
                                <option value="5">{{profile.months_short.may}}</option>
                                <option value="6">{{profile.months_short.jun}}</option>
                                <option value="7">{{profile.months_short.jul}}</option>
                                <option value="8">{{profile.months_short.aug}}</option>
                                <option value="9">{{profile.months_short.sep}}</option>
                                <option value="10">{{profile.months_short.oct}}</option>
                                <option value="11">{{profile.months_short.nov}}</option>
                                <option value="12">{{profile.months_short.dec}}</option>
                            </select>
                        </div>
                        <div class="col-sm-2">
                            <select class="form-select" data-testid="checkOutYear" v-model="toFields.year" required>
                                <option data-testid="checkOutYearResult" v-for="(year, index) in years" :key="index" :value="year" :selected="year === 2018">{{year}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <flat-pickr data-testid="checkOutTime" v-model="toFields.time" class="form-control" :config="flatPickrTimeConfig"></flat-pickr>
                        </div>
                    </div>
                    <div class="switch-wrapper" style="margin-top: 10px;" v-if="!supplier">
                        <label class="switch">
                            <input type="checkbox" v-model="fields.followup">
                            <span class="slider round" data-testid="followUpSwitch"></span>
                        </label>
                        <span><b>{{profile.incident_detail.followup_label}}</b></span>
                    </div>
                    <button type="submit" data-testid="checkInOutSubmit" class="button button--icon"><i class="fa fa-toolbox"></i>{{profile.incident_detail.checkout_mechanic_repair}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

export default {
    mixins: [setLocale],
    name: 'newLocationModal',
    components: {
        flatPickr
    },
    data() {
        return {
            profile: {},
            years: [],
            fromFields: {
                day: 1,
                month: 1,
                year: 2011,
                time: ''
            },
            toFields: {
                day: 1,
                month: 1,
                year: 2011,
                time: ''
            },
            fields: {
                followup: false
            },
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            }
        }
    },
    props: ['incidentId', 'appointmentId', 'supplier'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var startYear = 2018;
        while (startYear <= currentYear) {
            this.years.push(startYear);
            startYear++
        }
        this.fromFields.day = currentDate.getDate();
        this.fromFields.month = currentDate.getMonth() + 1;
        this.fromFields.year = currentDate.getFullYear();
        this.fromFields.time = moment(currentDate).format('HH:mm');
        this.toFields.day = currentDate.getDate();
        this.toFields.month = currentDate.getMonth() + 1;
        this.toFields.year = currentDate.getFullYear();
        this.toFields.time = moment(currentDate).format('HH:mm');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        manualCheckInOut() {
            let fromEpoch = new Date();
            fromEpoch.setDate(this.fromFields.day);
            fromEpoch.setMonth(this.fromFields.month - 1);
            fromEpoch.setFullYear(this.fromFields.year);
            let fromHours = this.fromFields.time.split(':')[0];
            let fromMinutes = this.fromFields.time.split(':')[1];
            fromEpoch.setHours(fromHours);
            fromEpoch.setMinutes(fromMinutes);
            fromEpoch.setSeconds(0);
            let toEpoch = new Date();
            toEpoch.setDate(this.toFields.day);
            toEpoch.setMonth(this.toFields.month - 1);
            toEpoch.setFullYear(this.toFields.year);
            let toHours = this.toFields.time.split(':')[0];
            let toMinutes = this.toFields.time.split(':')[1];
            toEpoch.setHours(toHours);
            toEpoch.setMinutes(toMinutes);
            toEpoch.setSeconds(0);
            const data = new URLSearchParams();
            data.append('id_appointment', this.appointmentId);
            data.append('checkin_time', fromEpoch.getTime());
            data.append('checkout_time', toEpoch.getTime());
            data.append('id_incident', this.incidentId);
            let self = this;
            fetch('/api/v1/incident/checkout_forrepair', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Specialist in- en uitchecken',
                });
                if (self.fields.followup) {
                    self.followUp();
                }
                else {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('checkout');
                    self.closeModal();
                }
            });
        },
        followUp() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('wants_copy', true);
            let self = this;
            fetch('/api/v1/incident/setcopywanted', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('checkout');
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
