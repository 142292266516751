<template>
    <div class="modal-wrapper" v-if="profile.owners">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-plus"></i>{{profile.owners.contacts.add_contact}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createContact()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.name.length, error: nameError}">
                                <label>{{profile.accountPage.account.contactname}} *</label>
                                <input type="text" ref="name" class="form-control" data-testid="contactNameInput" v-model="fields.name" @input="checkName" :disabled="confirmAdd">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label style="margin-bottom: 0;">{{profile.owners.contacts.role}}</label>
                            <p>{{profile.incident_detail.incident_owner}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.email.length, valid: validEmail, error: invalidEmail}">
                                <label>{{profile.incidents.new_incident.emailaddress}} *</label>
                                <input type="text" class="form-control" data-testid="contactEmailInput" v-model="fields.email" @input="validateEmail()">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.mobile.length, valid: validPhone, error: invalidPhone}">
                                <label>{{profile.accountPage.account.contactmobile}}</label>
                                <input type="text" class="form-control" data-testid="contactPhoneInput" v-model="fields.mobile" @input="validatePhone()">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.description.length}" style="margin-bottom: 0;">
                                <label>{{profile.text_languages.text_languagetable.description}}</label>
                                <input type="text" class="form-control" v-model="fields.description" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="switch-wrapper">
                                <label class="switch">
                                    <input type="checkbox" v-model="fields.createAccount">
                                    <span class="slider round"></span>
                                </label>
                                <span><b>{{profile.repair_companies.repaircompanytable.createAccount}}</b> <i class="fas fa-info-circle" v-tooltip.right="profile.owners.contacts.account_tooltip"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="notification notification--warning" style="margin: 20px 0 5px 0; width: 100%;" v-if="contactError">
                        <p>{{profile.owners.contacts.person_notification}}</p>
                    </div>
                    <div class="notification notification--warning" style="margin: 20px 0 5px 0; width: 100%;" v-if="userError">
                        <p>{{profile.owners.contacts.role_notification}}</p>
                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="roleError">
                        <span class="close-notification" @click="roleError = false"><i class="fas fa-times"></i></span>
                        <p style="padding-right: 20px;">{{profile.owners.contacts.role_error}}</p>
                        <span class="button button--small" @click="createActualContact()">{{profile.owners.contacts.add_contact_role}}</span>
                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="confirmAdd">
                        <span class="close-notification" @click="confirmAdd = false"><i class="fas fa-times"></i></span>
                        <p v-if="vve">{{profile.owners.contacts.confirm_add_vve}} {{ userToAddName }}.</p>
                        <p v-else>{{profile.owners.contacts.confirm_add}} {{ userToAddName }}.</p>
                        <p>{{profile.owners.contacts.confirm_add_2}}</p>
                        <span class="button button--small" @click="createOwner()">{{profile.owners.contacts.confirm_add_button}}</span>
                    </div>
                    <button type="submit" data-testid="contactSubmitButton" class="button button--icon" v-if="!confirmAdd && !userError && !roleError"><i class="fas fa-plus"></i>{{ profile.owners.contacts.add_contact }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            fields: {
                name: '',
                description: '',
                email: '',
                mobile: '',
                createAccount: false
            },
            validPhone: false,
            invalidPhone: false,
            validEmail: false,
            invalidEmail: false,
            contactError: false,
            nameError: false,
            userError: false,
            roleError: false,
            confirmAdd: false,
            userToAdd: 0,
            userToAddName: ''
        }
    },
    props: ['ownerId', 'contacts' , 'vve'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        validateEmail() {
            this.contactError = false;
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.fields.email)) {
                this.validEmail = true;
                this.invalidEmail = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        validatePhone() {
            let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            if (pattern.test(this.fields.mobile)) {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            else {
                this.invalidPhone = true;
                this.validPhone = false;
            }
            if (!this.fields.mobile.length) {
                this.invalidPhone = false;
            }
        },
        createContact() {
            this.validateEmail();
            if (this.validEmail) {
                for (var i = 0; i < this.contacts.length; i++) {
                    let contact = this.contacts[i];
                    if (contact.email === this.fields.email) {
                        this.contactError = true;
                    }
                }
            }
            if (!this.fields.name.length) {
                this.nameError = true;
            }
            if (this.fields.createAccount && !this.contactError) {
                this.createAccount();
            }
            if (!this.fields.createAccount && !this.contactError) {
                this.createActualContact();
            }      
        },
        createActualContact(noRefresh) {
            if (this.fields.name.length && this.validEmail && !this.invalidPhone) {
                const data = new URLSearchParams();
                let email = this.fields.email.toLowerCase();
                data.append('id_owner', this.ownerId);
                data.append('email', email);
                data.append('mobile', this.fields.mobile);
                data.append('name', this.fields.name);
                data.append('role_description', this.fields.description);
                let self = this;
                fetch('/api/v1/contactperson/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Aanmaken contactpersoon',
                    });
                    if (!noRefresh) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.$emit('success');
                        document.querySelector('body').classList.remove('modal-open');
                    }
                });
            }
        },
        checkName() {
            if (!this.fields.name.length) {
                this.nameError = true;
            }
            else {
                this.nameError = false;
            }
        },
        createAccount() {
            this.userError = false;
            this.roleError = false;
            const data = new URLSearchParams();
            data.append('username', this.fields.email);
            data.append('actual_name', this.fields.name);
            data.append('mobile', this.fields.mobile);
            let self = this;
            fetch('/api/v1/owneraccount/create', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                if (!data.EXISTS) {
                    self.createActualContact();
                    self.addOwner(data.id_shiro_user);
                }
                else {
                    if ((data.roles[0] && data.roles[0].name === 'owner') || (data.roles[1] && data.roles[1].name === 'owner') || (data.roles[2] && data.roles[2].name === 'owner')) {
                        const containsEmail = self.contacts.some((contact) => contact.email === self.fields.email);
                        if (!containsEmail) {
                            self.confirmAdd = true;
                            self.userToAdd = data.id_shiro_user;
                            self.userToAddName = data.actual_name;
                            self.fields.name = data.actual_name;
                        }
                        else {
                            self.userError = true;
                            setTimeout(function () {                        
                                self.addOwner(data.id_shiro_user);
                                self.createActualContact();
                            }.bind(this), 1000) 
                        }
                    }
                    if ((data.roles[0] && data.roles[0].name !== 'owner') || (data.roles[1] && data.roles[1].name !== 'owner') || (data.roles[2] && data.roles[2].name !== 'owner')) {
                        self.roleError = true;
                    }
                    if (!data.roles.length) {
                        self.confirmAdd = true;
                    }
                }
            });
        },
        addOwner(shiroUser) {
            let userId;
            if (!shiroUser) {
                userId = this.userToAdd;
            }
            else {
                userId = shiroUser;
            }
            const data = new URLSearchParams();
            data.append('id_shiro_user', userId);
            data.append('id_owner', this.ownerId);
            let self = this;
            fetch('/api/v1/owneraccount/addowner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                setTimeout(function () {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Aanmaken account contactpersoon',
                    });
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                    document.querySelector('body').classList.remove('modal-open');
                }.bind(this), 1000) 
                
            });
        },
        createOwner() {
            this.createActualContact(true);
            if (this.userToAdd) {
                this.addOwner(this.userToAdd);
            }
            else {
                this.createAccount();
            }
        }
    }
}
</script>
