<template>
  <header id="header" v-if="profile.nav">
      <v-idle
        @idle="onidle"
        :loop="true"
        :duration="14400"
        style="display: none;" />
    <div class="container">
        <div @click="closeNav()">
            <router-link data-testid="tabDashboardLogo" tag="a" to="/buildingmanager" class="logo"><img src="@/assets/img/logo.png" /></router-link>
        </div>
        <nav class="d-none d-md-block d-lg-block" :class="{active: mobileNavToggle}" v-if="paid">
            <router-link @click.native="openNav()" data-testid="tabDashboard" tag="a" to="/buildingmanager">{{profile.nav.dashboard}}</router-link>
            <router-link @click.native="openNav()" data-testid="tabOwners" tag="a" to="/buildingmanager/owners"><span v-if="vve">{{profile.nav.vves}}</span><span v-else>{{profile.nav.owners}}</span></router-link>
            <router-link @click.native="openNav()" data-testid="tabSupplier" tag="a" to="/buildingmanager/repaircompanies" v-if="(twinq && admin) || !twinq">{{profile.nav.repaircompanies}}</router-link>
            <router-link @click.native="openNav()" data-testid="tabLocation" tag="a" to="/buildingmanager/locations" v-if="(twinq && admin) || !twinq">{{profile.nav.places}}</router-link>
            <router-link @click.native="openNav()" data-testid="tabAsset" tag="a" to="/buildingmanager/assets">{{profile.nav.assets}} <span class="counter" v-if="assetsWithoutType" v-tooltip.right="profile.nav.assets_without_type_tooltip">{{assetsWithoutType}}</span></router-link>
            <span data-testid="tabModules" class="sub" :class="{active: modulesOpen}" @click.stop="modulesOpen = !modulesOpen" v-if="(recordings || repeatingWork) && !twinq" v-click-outside="closeModules">Modules <span class="counter" v-if="overtime">{{ overtime }}</span> <i class="fas fa-chevron-down"></i>
                <div class="sub-menu" v-show="modulesOpen">
                    <router-link tag="a" class="d-block d-md-none" to="/buildingmanager/recordings" v-if="recordings" @click.native="openNav()">{{profile.actionable.recordings}}</router-link>
                    <router-link tag="a" class="d-block d-md-none" to="/buildingmanager/repeating-work" v-if="repeatingWork" @click.native="openNav()">{{profile.actionable.repeating_job}} <span class="counter" v-if="overtime">{{ overtime }}</span></router-link>
                    <router-link tag="a" data-testid="tabRecordings" class="d-none d-md-block d-lg-block" to="/buildingmanager/recordings" v-if="recordings">{{profile.actionable.recordings}}</router-link>
                    <router-link tag="a" data-testid="tabRepeating" class="d-none d-md-block d-lg-block" to="/buildingmanager/repeating-work" v-if="repeatingWork">{{profile.actionable.repeating_job}} <span class="counter" v-if="overtime">{{ overtime }}</span></router-link>
                </div>
            </span>
        </nav>
        <nav class="d-none d-md-block d-lg-block" :class="{active: mobileNavToggle}" @click="openNav()" v-else>
            <router-link data-testid="tabDashboard" tag="a" to="/buildingmanager">{{profile.nav.dashboard}}</router-link>
            <router-link data-testid="tabSupplier" tag="a" to="/buildingmanager/repaircompanies"  v-if="(twinq && admin) || !twinq">{{profile.nav.repaircompanies}}</router-link>
            <span v-if="vve" v-tooltip.right="profile.accountPage.account.autodispatch_feature"><i class="fas fa-lock"></i> {{profile.nav.vves}}</span><span v-tooltip.right="profile.accountPage.account.autodispatch_feature" v-else><i class="fas fa-lock"></i> {{profile.nav.owners}}</span>
            <span v-tooltip.right="profile.accountPage.account.autodispatch_feature"><i class="fas fa-lock"></i> {{profile.nav.places}}</span>
            <span v-tooltip.right="profile.accountPage.account.autodispatch_feature"><i class="fas fa-lock"></i> {{profile.nav.assets}}</span>
        </nav>
        <section class="utils">
            <div class="util util--search" data-testid="headerSearchIncident" @click="toggleSearch()" :class="{active: searchActive}"><i class="fas fa-search"></i></div>
            <span class="chat-toggle" @click="showImportantNotes = !showImportantNotes" :class="{active: showImportantNotes}" v-if="importantNotes.length"><i class="fas fa-sticky-note"></i><b>{{importantNotes.length}}</b></span>
            <span class="chat-toggle" @click="showUnreadChats = !showUnreadChats" :class="{active: showUnreadChats}" v-if="totalUnread && !twinq" v-click-outside="closeUnread"><i class="fas fa-comment"></i><b>{{totalUnread}}</b></span>
            <span class="util util--language" v-if="langSwitch"><img v-if="locale === 'nl'" src="@/assets/icons/dutch.svg" /><img v-if="locale === 'en'" src="@/assets/icons/english.svg" />
                <ul class="dropdown-nav">
                    <span class="util util--language" data-testid="managerTabDutch" v-if="locale === 'en'" @click="setLang('nl')"><img src="@/assets/icons/dutch.svg" /></span>
                    <span class="util util--language" data-testid="managerTabEnglish" v-if="locale === 'nl'" @click="setLang('en')"><img src="@/assets/icons/english.svg" /></span>
                </ul>
            </span>
            <span class="util util--user" @click="showUtils = !showUtils" v-click-outside="closeUtils"><i class="fas fa-user"></i></span>
            <ul class="dropdown-nav large" data-testid="tabProfile" v-show="showUtils">
                <router-link data-testid="tabAccount" tag="li" to="/buildingmanager/account" @click.native="closeNav()">{{profile.nav.account.account}}</router-link>
                <router-link data-testid="tabConnect" tag="li" v-if="admin && (twinq && admin) || !twinq" to="/buildingmanager/connect" @click.native="closeNav()">{{profile.nav.connect}}</router-link>
                <li v-if="historyList.length && !twinq" data-testid="tabHistory" @click="historyModalOpen = true">{{profile.nav.incident_history}}</li>
                <router-link data-testid="tabAppointments" tag="li" to="/buildingmanager/planning" @click.native="closeNav()">Planning</router-link>
                <router-link data-testid="tabNews" tag="li" to="/buildingmanager/news" @click.native="closeNav()">{{profile.nav.news}}</router-link>
                <router-link tag="li" to="/buildingmanager/statistics" @click.native="closeNav()">{{profile.nav.statistics}}</router-link>
                <li><a href="/api/v1/logout" data-testid="tabLogout" style="display: block; width: 47px; text-decoration: none; color: #212529;">{{profile.nav.account.logout}}</a></li>
            </ul>
        </section>
        <div class="chats-window" v-if="showUnreadChats">
            <i class="fa fa-times" @click="showUnreadChats = false" style="position: absolute; right: 10px; top: 10px; cursor: pointer; font-size: 16px;"></i>
            <h3>{{profile.dashboard.building_manager.messages}}</h3>
            <p v-if="!totalUnread" style="padding-left: 10px; margin-bottom: 10px;">{{profile.dashboard.building_manager.messages_expla}}</p>
            <div class="unread-chats">
                <div class="unread-chat" v-for="(chat, index) in unreadChats" :key="index" v-show="chat.body || chat.media_url || chat.owner_name">
                    <div @click="toIncident(chat, true, false)">
                        <div class="labels">
                            <span class="incident-id">{{chat.id_incident}}</span>
                            <div class="current-user"><i class="fas fa-user"></i> {{chat.assigned_to_name}}</div>
                        </div>
                        <div class="chat-item" v-if="(chat.body && chat.body !== chat.ownerBody && chat.body !== chat.repairCompanyBody && chat.body !== chat.mechanicBody) || chat.media_url">
                            <span class="rep-name">{{chat.reporter_name}}</span>
                            <span class="role-label">{{profile.incident_detail.incident_reporter}}</span>
                            <div class="message-body">
                                <div v-if="chat.media_url">
                                    <img :src="chat.media_url" v-if="chat.media_content_type && chat.media_content_type.includes('image/')" style="max-width: 50px; max-height: 50px; border-radius: 5px;" />
                                    <video v-if="chat.media_content_type === 'video/mp4'" style="max-width: 50px; max-height: 50px; border-radius: 5px;" autoplay loop muted><source :src="chat.media_url" type="video/mp4"></video>
                                </div>
                                <p v-else>{{chat.body}}</p><i>•</i><span class="timedate" v-if="chat.date">{{chat.date}}</span>
                            </div>
                            <span class="setasread" @click.stop="setwhatsappasread(chat)"><img src="@/assets/icons/checkchat.svg"></span>
                            <span class="counter"><b>{{chat.count}}</b></span>
                        </div>
                        <div class="chat-item" v-if="chat.ownerBody">
                            <span class="rep-name">{{chat.ownerName}}</span>
                            <span class="role-label">{{profile.incident_detail.incident_owner}}</span>
                            <div class="message-body">
                                <div v-if="chat.media_url">
                                    <img :src="chat.media_url" v-if="chat.media_content_type && chat.media_content_type.includes('image/')" style="max-width: 50px; max-height: 50px; border-radius: 5px;" />
                                    <video v-if="chat.media_content_type === 'video/mp4'" style="max-width: 50px; max-height: 50px; border-radius: 5px;" autoplay loop muted><source :src="chat.media_url" type="video/mp4"></video>
                                </div>
                                <p v-if="!chat.media_url && chat.ownerBody">{{chat.ownerBody}}</p><i v-if="chat.ownerBody">•</i><span class="timedate" v-if="chat.ownerDate">{{chat.ownerDate}}</span>
                            </div>
                            <span class="setasread" @click.stop="setwhatsappasread(chat)"><img src="@/assets/icons/checkchat.svg"></span>
                            <span class="counter counter--owner" v-if="chat.ownerBody"><b>{{chat.ownerCount}}</b></span>
                        </div>
                        <div class="chat-item" v-if="chat.repairCompanyBody">
                            <span class="rep-name">{{chat.repair_company_name}}</span>
                            <span class="role-label">{{profile.incident_detail.repair_company_comment}}</span>
                            <div class="message-body">
                                <div v-if="chat.media_url">
                                    <img :src="chat.media_url" v-if="chat.media_content_type && chat.media_content_type.includes('image/')" style="max-width: 50px; max-height: 50px; border-radius: 5px;" />
                                    <video v-if="chat.media_content_type === 'video/mp4'" style="max-width: 50px; max-height: 50px; border-radius: 5px;" autoplay loop muted><source :src="chat.media_url" type="video/mp4"></video>
                                </div>
                                <p v-if="!chat.media_url && chat.repairCompanyBody">{{chat.repairCompanyBody}}</p><i v-if="chat.repairCompanyBody">•</i><span class="timedate" v-if="chat.repairCompanyDate">{{chat.repairCompanyDate}}</span>
                            </div>
                            <span class="counter counter--owner" v-if="chat.repairCompanyBody"><b>{{chat.repairCompanyCount}}</b></span>
                        </div>
                        <div class="chat-item" v-if="chat.mechanicBody">
                            <span class="rep-name">{{chat.mechanicName}}</span>
                            <span class="role-label">{{profile.incident_detail.mechanic_comment}}</span>
                            <div class="message-body">
                                <div v-if="chat.media_url">
                                    <img :src="chat.media_url" v-if="chat.media_content_type && chat.media_content_type.includes('image/')" style="max-width: 50px; max-height: 50px; border-radius: 5px;" />
                                    <video v-if="chat.media_content_type === 'video/mp4'" style="max-width: 50px; max-height: 50px; border-radius: 5px;" autoplay loop muted><source :src="chat.media_url" type="video/mp4"></video>
                                </div>
                                <p v-if="!chat.media_url && chat.mechanicBody">{{chat.mechanicBody}}</p><i v-if="chat.mechanicBody">•</i><span class="timedate" v-if="chat.mechanicDate">{{chat.mechanicDate}}</span>
                            </div>
                            <span class="setasread" @click.stop="setwhatsappasread(chat)"><img src="@/assets/icons/checkchat.svg"></span>
                            <span class="counter counter--owner" v-if="chat.mechanicBody"><b>{{chat.mechanicCount}}</b></span>
                        </div>
                        <div class="chat-item" v-if="chat.owner_name">
                            <span class="rep-name">{{chat.owner_name}}</span>
                            <span class="role-label">{{profile.incident_detail.incident_owner}}</span>
                            <div class="message-body">
                                <div v-if="chat.media_url">
                                    <img :src="chat.media_url" v-if="chat.media_content_type && chat.media_content_type.includes('image/')" style="max-width: 50px; max-height: 50px; border-radius: 5px;" />
                                    <video v-if="chat.media_content_type === 'video/mp4'" style="max-width: 50px; max-height: 50px; border-radius: 5px;" autoplay loop muted><source :src="chat.media_url" type="video/mp4"></video>
                                </div>
                                <p v-if="!chat.media_url && chat.ownerUserBody">{{chat.ownerUserBody}}</p><i v-if="chat.ownerUserBody">•</i><span class="timedate" v-if="chat.date">{{chat.date}}</span>
                            </div>
                            <span class="setasread" @click.stop="setwhatsappasread(chat)"><img src="@/assets/icons/checkchat.svg"></span>
                            <span class="counter counter--owner" v-if="chat.ownerUserBody"><b>{{chat.count}}</b></span>
                        </div>
                    </div>
                    <span class="new-window" @click="toIncident(chat, true, true)"><img src="@/assets/icons/open.svg"></span>
                </div>
            </div>
        </div>
        <div class="chats-window notes" v-if="showImportantNotes && importantNotes.length">
            <i class="fa fa-times" @click="showImportantNotes = false" style="position: absolute; right: 10px; top: 10px; cursor: pointer; font-size: 16px;"></i>
            <h3 style="border-bottom: 1px solid #ebebeb; padding-bottom: 10px; margin-bottom: 0;">Belangrijke notities</h3>
            <div class="unread-chats">
                <div class="unread-chat" v-for="(note, index) in importantNotes" :key="index">
                    <div @click="selectNote(note)">
                        <div class="labels" style="padding-right: 0;">
                            <span class="incident-id" style="right: 5px;">{{note.id_incident}}</span>
                            <div class="current-user"><i class="fas fa-user"></i> {{note.actual_name}}</div>
                        </div>
                        <div class="chat-item" style="padding-left: 1px;">
                            <span class="rep-name">{{note.reporter_name}}</span>
                            <div class="message-body">
                                <p style="width: 100%;">{{note.content}}</p>
                                <i>•</i><span class="timedate" v-if="note.date" style="margin-top: 2px;">{{note.date}}</span>
                            </div>
                        </div>           
                    </div>
                </div>
            </div>
        </div>
        <span class="open-nav d-block d-md-none" @click="openNav()" v-if="!mobileNavToggle"><i class="fas fa-bars"></i></span>
        <span class="open-nav d-block d-md-none" @click="openNav()" v-if="mobileNavToggle"><i class="fa fa-times"></i></span>
        <IncidentHistoryModal v-if="historyModalOpen" @close="closeModal()" :historyList="historyList" />
        <NewsModal v-if="news && (news.for_role === 'ALL' || news.for_role === 'BUILDINGMANAGER')" @close="newsRead()" :news="news" />
    </div>
    <div class="container container--search" v-if="searchActive" style="position: relative;">
            <div class="search-window">
                <section class="search dashboard" :class="{active: searchSuggestions.length}">
                    <input class="d-none d-md-block d-lg-block" type="text" ref="search1" data-testid="searchIncident" v-bind:placeholder="profile.dashboard.building_manager.search" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="searchSuggest($event)" style="margin-bottom: 0;" />
                    <input class="d-block d-md-none" type="text" ref="search2" data-testid="searchIncident" v-bind:placeholder="profile.dashboard.building_manager.search_short" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="searchSuggest($event)" style="margin-bottom: 0;" />
                    <span class="close-suggestions" @click="clearSuggestions()" v-if="searchTerm.length"><i class="fas fa-times"></i></span>
                    <ul class="search-suggestions" v-if="searchSuggestions.length" style="margin-top: 0;">
                        <h4 class="result-counter" v-if="totalSuggestions === 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                        <h4 class="result-counter" v-if="totalSuggestions > 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                        <li v-for="(suggestion, index) in searchSuggestions" :key="index" @click="toIncident(suggestion)" v-tooltip.center="suggestion.incident_description">
                            <div class="indicators">
                                <span class="number" data-testid="searchIncidentNumber">{{suggestion.id_incident}}</span>
                                <span class="auto-forward" v-if="suggestion.id_ori_incident">auto <i class="fas fa-forward"></i></span>
                                <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_PERSON' || suggestion.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                <span class="status" data-testid="searchIncidentStatus" v-if="!profile['statusses'][suggestion.status].buildingmanager">
                                    {{profile['statusses'][suggestion.status]}}
                                    <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                </span>
                                <span class="status" data-testid="searchIncidentStatus" v-if="profile['statusses'][suggestion.status].buildingmanager">
                                    {{profile['statusses'][suggestion.status].buildingmanager}}
                                    <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                </span>
                                <span class="date">{{profile.incidents.incidenttable.since}}{{suggestion.date}} {{suggestion.time}}</span>
                            </div>
                            <span class="date" style="clear: both; display: block; margin-left: 0;">{{profile.incidents.incidenttable.datetime}}: {{suggestion.createdDate}}</span>
                            <p data-testid="searchPlaceText">{{suggestion.place_name}}, {{suggestion.address}} {{suggestion.house_number}}</p>
                            <span class="asset">{{suggestion.asset_name}}</span>
                            <span class="repaircompany" v-if="suggestion.repair_company_name" style="display: block; font-weight: 500; clear:both; margin-top: 3px;"><i class="fas fa-tools"></i> {{suggestion.repair_company_name}}</span>
                            <span class="parked" v-if="suggestion.suspended"><i class="fas fa-parking"></i> {{profile.incident_detail.parked}}</span>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { EventBus } from '@/js/event-bus.js';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import IncidentHistoryModal from "@/components/modals/IncidentHistoryModal.vue";
import NewsModal from "@/components/modals/NewsModal.vue";
import { websocketBm } from '@/mixins/websocket-bm.js';
import moment from 'moment';
import debounce from 'lodash/debounce';

export default {
    name: 'Header',
    mixins: [websocketBm],
    components: {
        IncidentHistoryModal,
        NewsModal
    },
    data: function () {
        return {
            mobileNavToggle: false,
            industry: '',
            profile: {},
            locale: '',
            bmInterval: null,
            langSwitch: true,
            bmId: 0,
            authenticated: false,
            admin: false,
            vve: false,
            twinq: false,
            paid: false,
            showUnreadChats: false,
            unreadChats: [],
            totalUnread: 0,
            showUtils: false,
            historyList: [],
            historyModalOpen: false,
            recordings: false,
            repeatingWork: false,
            assetsWithoutType: 0,
            modulesOpen: false,
            overtime: 0,
            news: null,
            importantNotes: [],
            showImportantNotes: false,
            searchActive: false,
            searchTerm: '',
            totalSuggestions: 0,
            searchSuggestions: []
        }
    },
    computed: mapState(['chatRead', 'incidentsHistory', 'statsGet', 'getImportantNotes']),
    watch: {
        chatRead() {
            if(this.chatRead) {
                this.showUnread();
            }
        },
        incidentsHistory() {
            this.historyList = this.incidentsHistory;
        },
        statsGet() {
            this.getStats();
        },
        getImportantNotes() {
            this.getImportantNotess();
        },
        $route (to, from) {
            let now = new Date().getTime();
            let routeObject = {
                "type": "route_update",
                "timestamp": now,
                "data": {
                    "route": to.fullPath,
                    "from_route": from.fullPath
                }
            }
            this.sendRoute(routeObject);
        }
    },
    created() {
        fetch('/api/v1/isauthenticated')
        .then(response => { response.text().then(
            function(data) {
                if (data === 'false') {
                    window.location = '/signin.html';
                }
            });
        });
        this.getStats();
        this.getBm();
        if (localStorage.getItem('S1-historylist')) {
            this.historyList = JSON.parse(localStorage.getItem('S1-historylist'));
        }
        this.bmInterval = setInterval(function () {
            this.getBm();
        }.bind(this), 1800000);
    },
    beforeDestroy() {
        clearInterval(this.bmInterval);
    },
    methods: {
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        onidle() {
            window.location = '/signin.html?timeout=true';
       },
        openNav() {
            this.mobileNavToggle = !this.mobileNavToggle;
            this.modulesOpen = false;
            let el = document.body;
            if (this.mobileNavToggle) {
                el.classList.add("modal-open");
            }
            if (!this.mobileNavToggle) {
                el.classList.remove("modal-open");
            }
        },
        closeNav() {
            this.mobileNavToggle = false;
            let el = document.body;
            el.classList.remove("modal-open");
            this.showUtils = false;
        },
        setLang(lang) {
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Taalswitch',
            });
            this.locale = lang;
            if (this.industry === 'vvemanagement') {
                this.industry = 'housing';
            }
            let busObj = {
                locale: lang,
                industry: this.industry
            }
            localStorage.setItem('S1-locale', lang);
            EventBus.$emit('langChange', busObj);
            this.profile = require('../../assets/locales/' + this.locale + '/' + this.industry + '.json');
        },
        getBm() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.admin = data.building_manager_admin;
                    self.paid = data.is_paid_account;
                    self.recordings = data.has_actionable_work;
                    self.repeatingWork = data.recurring_job_module;
                    self.overtime = data.recurring_past_action_date;
                    self.news = data.new_news[0];
                    if (self.admin) {
                        self.$store.dispatch('updateAdmin', true);
                    }
                    if (self.repeatingWork) {
                        self.$store.dispatch('updateRepeatingModule', true);
                    }
                    if (self.overtime) {
                        self.$store.dispatch('updateOvertime', self.overtime);
                    }
                });
            });
        },
        getStats() {
            let self = this;
            fetch('/api/v1/buildingmanagerstats')
            .then(response => { response.json().then(
                function(data) {
                    let industryId = data.building_manager.industry_constant;
                    if (!industryId) {
                        industryId = "HOUSING";
                    }
                    if (industryId === 'LEASING') {
                        self.industry = 'leasing';
                    }
                    if (industryId === 'HOUSING' || industryId === 'VVEMANAGEMENT') {
                        self.industry = 'housing';
                    }    
                    if (industryId === 'FACILITY') {
                        self.industry = 'facility';
                        self.langSwitch = false;
                    }
                    localStorage.setItem('S1-industry', self.industry);
                    if (data.building_manager.is_vve) {
                        self.vve = true;
                        self.$store.dispatch('updateVve', true);
                    }
                    if (data.building_manager.twinq_flow) {
                        self.twinq = data.building_manager.twinq_flow;
                        self.$store.dispatch('updateTwinq', true);
                    }
                    if (data.building_manager.has_agreement_flow) {
                        self.$store.dispatch('updateAgreementflow', true);
                    }
                    if (self.bmId) {
                        if (self.bmId !== data.building_manager.id_building_manager.toString()) {
                            localStorage.removeItem('S1-locationsSearchTerm');
                            localStorage.removeItem('S1-locationsSorter');
                            localStorage.removeItem('S1-locationsSortOrder');
                            localStorage.removeItem('S1-locationsPage');
                            localStorage.removeItem('S1-assetsSearchTerm');
                            localStorage.removeItem('S1-assetsSorter');
                            localStorage.removeItem('S1-assetsSortOrder');
                            localStorage.removeItem('S1-assetsPage');
                        }
                    }
                    self.$store.dispatch('updateBmId', data.building_manager.id_building_manager);
                    self.$store.dispatch('updateServiceQr', data.building_manager.service_qr_code);
                    self.$store.dispatch('updateServiceteam', data.building_manager.canbe_id_repair_company);
                    let locale = localStorage.getItem('S1-locale');
                    if (locale === null) {
                        locale = 'nl';
                    }
                    self.locale = locale;
                    if (window.location.href.includes('login=true')) {
                        if (window.location.href.includes('locale')) {
                            if (self.getUrlParameter('locale') === 'en') {
                                self.locale = 'en';
                                localStorage.setItem('S1-locale', 'en');
                            }
                        }
                    }
                    if (window.location.href.includes('login=true')) {
                        if (!window.location.href.includes('locale')) {
                            self.locale = 'nl';
                            localStorage.setItem('S1-locale', 'nl');
                        }
                    }
                    self.profile = require('../../assets/locales/' + self.locale + '/' + self.industry + '.json');
                    self.showUnread();
                    self.getImportantNotess();
                    if (data.assets_without_asset_type) {
                        self.$store.dispatch('updateAssetsWithoutTypeCount', data.assets_without_asset_type);
                        self.assetsWithoutType = Number(data.assets_without_asset_type);
                    }
                    self.$store.dispatch('updateUpdateTypes');
                });
            });
        },
        showUnread() {
            let self = this;
            fetch('/api/v1/chat/unreadall')
            .then(response => { response.json().then(
                function(data) {
                    self.$store.dispatch('updateChatRead2');
                    self.unreadChats = [];
                    self.totalUnread = 0;
                    let reporterChats = data.reporter;
                    if (reporterChats.length) {
                        for (var i = 0; i < reporterChats.length; i++) {
                            let item = reporterChats[i];
                            let dateSent = new Date(item.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            item.date = dateStr;
                            self.unreadChats.push(item);
                        }
                    }
                    let serviceDeskChats = data.servicedesk;
                    if (serviceDeskChats.length) {
                        for (var i = 0; i < serviceDeskChats.length; i++) {
                            let item = reporterChats[i];
                            let dateSent = new Date(item.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            item.date = dateStr;
                            self.unreadChats.push(item);
                        }
                    }
                    let repairCompanyChats = data.repaircompany;
                    if (repairCompanyChats.length) {
                        for (var i = 0; i < repairCompanyChats.length; i++) {
                            let repairCompanyItem = repairCompanyChats[i];
                            let dateSent = new Date(repairCompanyItem.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            repairCompanyItem.date = dateStr;
                            for (var j = 0; j < self.unreadChats.length; j++) {
                                let reporterItem = self.unreadChats[j];
                                if (repairCompanyItem.id_incident === reporterItem.id_incident) {
                                    reporterItem.ownerType = repairCompanyItem.type;
                                    reporterItem.repairCompanyBody = repairCompanyItem.body;
                                    reporterItem.repairCompanyName = repairCompanyItem.repair_company_name;
                                    reporterItem.repairCompanyDate = repairCompanyItem.date;
                                    reporterItem.repairCompanyCount = repairCompanyItem.count;
                                }
                                if (repairCompanyItem.id_incident !== reporterItem.id_incident && !reporterItem.repairCompanyBody) {
                                    self.unreadChats.push(repairCompanyItem);
                                }
                            }
                        }
                    }
                    let mechanicChats = data.mechanic;
                    if (mechanicChats.length) {
                        for (var i = 0; i < mechanicChats.length; i++) {
                            let mechanicItem = mechanicChats[i];
                            let dateSent = new Date(mechanicItem.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            mechanicItem.date = dateStr;
                            for (var j = 0; j < self.unreadChats.length; j++) {
                                let reporterItem = self.unreadChats[j];
                                if (mechanicItem.id_incident === reporterItem.id_incident) {
                                    reporterItem.mechanicType = mechanicItem.type;
                                    reporterItem.mechanicBody = mechanicItem.body;
                                    reporterItem.mechanicName = mechanicItem.mechanic_name;
                                    reporterItem.mechanicDate = mechanicItem.date;
                                    reporterItem.mechanicCount = mechanicItem.count;
                                }
                                if (mechanicItem.id_incident !== reporterItem.id_incident && !reporterItem.mechanicBody) {
                                    self.unreadChats.push(mechanicItem);
                                }
                            }
                            self.unreadChats = uniqBy(self.unreadChats, 'id_incident');
                        }
                    }
                    let ownerChats = data.owner;
                    if (ownerChats.length) {
                        for (var i = 0; i < ownerChats.length; i++) {      
                            let ownerItem = ownerChats[i];
                            let dateSent = new Date(ownerItem.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            ownerItem.date = dateStr;
                            for (var j = 0; j < self.unreadChats.length; j++) {
                                let reporterItem = self.unreadChats[j];
                                if (ownerItem.id_incident === reporterItem.id_incident) {
                                    reporterItem.ownerType = ownerItem.type;
                                    reporterItem.ownerBody = ownerItem.body;
                                    reporterItem.ownerName = ownerItem.owner_name;
                                    reporterItem.ownerDate = ownerItem.date;
                                    reporterItem.ownerCount = ownerItem.count;
                                }
                                if (ownerItem.id_incident !== reporterItem.id_incident && !reporterItem.ownerBody) {
                                    self.unreadChats.push(ownerItem);
                                }
                            }
                        }
                    }
                    let ownerUserChats = data.owneruser;
                    if (ownerUserChats.length) {
                        for (var i = 0; i < ownerUserChats.length; i++) {
                            let ownerUserItem = ownerUserChats[i];
                            let dateSent = new Date(ownerUserItem.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            ownerUserItem.date = dateStr;
                            ownerUserItem.ownerUserBody = ownerUserItem.body;
                            ownerUserItem.body = '';
                            self.unreadChats.push(ownerUserItem);
                        }
                    }
                    if (self.unreadChats.length) {
                        self.unreadChats = uniqBy(self.unreadChats, 'id_incident');
                        for (var k = 0; k < self.unreadChats.length; k++) {
                            let item = self.unreadChats[k];
                            if (item.body || item.media_content_type) {
                                let totalCount = item.count;
                                if (item.ownerCount && item.body !== item.ownerBody) {
                                    totalCount = totalCount + item.ownerCount;
                                }
                                self.totalUnread = self.totalUnread + totalCount;
                            }
                        }
                    }
                    self.unreadChats = sortBy(self.unreadChats, 'created');
                });
            });
        },
        getImportantNotess() {
            this.importantNotes = [];
            let self = this;
            fetch('/api/v1/incident_note/unreadnotifications')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let dateSent = new Date(item.created);
                        let month = dateSent.getMonth() + 1;
                        month = month.toString();
                        let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                        item.date = dateStr;
                        self.importantNotes.push(item);
                    }
                });
            });
        },
        selectNote(note) {
            this.showImportantNotes = false;
            this.$router.push('/buildingmanager/incident-detail?id=' + note.id_incident + '&opennote=' + note.id_incident_note);
        },
        pad(n) {
            return n < 10 ? '0' + n : n
        },
        setwhatsappasread(chat) {
            let type;
            if (chat.type === 'whatsapp_incoming_reporter') {
                type = 'reporter';
            }
            if (chat.type === 'mechanic_to_service_desk' || chat.mechanicType === 'mechanic_to_service_desk') {
                type = 'mechanic';
            }
            if (chat.type === 'whatsapp_incoming_owner' || chat.ownerType === 'whatsapp_incoming_owner') {
                type = 'owner';
            }
            if (chat.type === 'service_desk_to_service_desk') {
                type = 'servicedesk';
            }
            if (chat.type === 'owneruser_to_service_desk') {
                type = 'owneruser';
            }
            const data = new URLSearchParams();
            data.append('id_incident', chat.id_incident);
            data.append('type', type);
            let self = this;
            fetch('/api/v1/chat/setwhatsappasread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                setTimeout(function() {
                    self.showUnread();
                }.bind(self), 500);
            });
        },
        toIncident(incident, openChat, newWindow) {
            this.searchActive = false;
            let openChatParam = '';
            let currentIncidentId = '';
            if (window.location.href.includes('id=')) {
                currentIncidentId = this.getUrlParameter('id');
            }
            if (openChat) {
                if (incident.type === 'whatsapp_incoming_reporter') {
                    openChatParam = 'reporterChat';
                }
                if (incident.type === 'mechanic_to_service_desk') {
                    openChatParam = 'specialistChat';
                }
                if (incident.type === 'firestore') {
                    openChatParam = 'repairCompanyChat';
                }
                if (incident.type === 'whatsapp_incoming_owner') {
                    openChatParam = 'ownerChat';
                }
                if (incident.type === 'owneruser_to_service_desk') {
                    openChatParam = 'ownerSeperateChat';
                }
                if (incident.type === 'supplier_to_service_desk') {
                    openChatParam = 'supplierChat';
                }
            }
            if (newWindow) {
                if (currentIncidentId && currentIncidentId !== incident.id_incident) {
                    const routeData = this.$router.resolve({path: '/buildingmanager/incident-detail', query: {id: incident.id_incident, openchat: openChatParam}});
                    window.open(routeData.href, '_blank');
                }
                if (!currentIncidentId) {
                    const routeData = this.$router.resolve({path: '/buildingmanager/incident-detail', query: {id: incident.id_incident, openchat: openChatParam}});
                    window.open(routeData.href, '_blank');
                }
            }
            else {
                if (currentIncidentId && currentIncidentId !== incident.id_incident.toString()) {
                    this.$router.push('/buildingmanager/incident-detail?id=' + incident.id_incident + '&openchat=' + openChatParam);
                    this.showUnreadChats = false;
                }
                if (!currentIncidentId) {
                    this.$router.push('/buildingmanager/incident-detail?id=' + incident.id_incident + '&openchat=' + openChatParam);
                    this.showUnreadChats = false;
                }
            }
        },
        closeModal() {
            this.historyModalOpen = false;
            this.showUtils = false;
        },
        newsRead() {
            this.news = false;
            fetch('/api/v1/newsmessage/hasread', {
                method: 'post'
            }).then((resp) => {return resp.json();
            }).then(function(data) {});
        },
        updateItem(updateObj) {
            this.$store.dispatch('updateIncident', updateObj);
        },
        closeUtils() {
            this.showUtils = false;
        },
        closeUnread() {
            this.showUnreadChats = false;
        },
        closeModules() {
            this.modulesOpen = false;
        },
        toggleSearch() {
            this.searchActive = !this.searchActive;
            if (this.searchActive) {
                setTimeout(function() {
                    this.$refs.search1.focus();
                    this.$refs.search2.focus();
                }.bind(this), 400);
            }  
        },
        searchSuggest: debounce(function (e) {
            this.searchSuggestions = [];
            if (typeof e === 'string') {
                this.searchTerm = e;
            }
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                let self = this;
                fetch('/api/v1/incidentcombined/search?search_term=' + searchTerm)
    	        .then(response => { response.json().then(
    	            function(data) {
                        self.totalSuggestions = data.data.length;
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (self.locale === 'nl') {
                                item.date = moment(item.last_status_time).locale('nl').format('D MMMM YYYY');
                            }
                            if (self.locale === 'en') {
                                item.date = moment(item.last_status_time).locale('en').format('MMMM D YYYY');
                            }
                            item.createdDate = moment(item.created).locale('nl').format('D MMMM YYYY, HH:mm');
                            item.time = moment(item.last_status_time).locale('nl').format('LT');
                            self.searchSuggestions.push(item);
                        }
    				});
    	        });
            }
            else {
                this.searchActive = false;
                this.searchSuggestions = [];
                this.totalSuggestions = 0;
            }
            if (e !== undefined) {
                if(e.keyCode === 27) {
                    this.searchActive = false;
                    this.searchTerm = '';
                    this.searchSuggestions = [];
                    this.totalSuggestions = 0;
                }
            }
        }, 500),
        removeTerm(item) {
            this.searchTerm = '';
            let index = this.searchTerms.indexOf(item);
            this.searchTerms.splice(index, 1);
            localStorage.setItem('S1-searchterms', JSON.stringify(this.searchTerms));
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
        }
    }
}
</script>
