<template>
    <div class="new-recordings">
        <div class="row" v-if="planningSaved">
            <div class="col">
                <div class="notification notification--success" style="float: left; width: 270px; margin-bottom: 20px;"><i class="fas fa-check"></i>{{profile.actionable.planning_saved}}</div>
            </div>
        </div>
        <div class="row" v-if="!collectedAssets.length">
            <div class="col">
                <div v-if="!selectedLists.length">
                    <h2>1. {{profile.actionable.choose_recording_list}}</h2>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.actionable.recordingslist}}</label>
                            <div class="dropdown-section" v-click-outside="closeListDropdown">
                                <span class="dropdown" data-testid="listsDropdown" v-on:click="listDropdownToggled = !listDropdownToggled" :class="{active: listDropdownToggled}">
                                <span v-if="!selectedList.id_actionable_list">{{profile.actionable.recordingslist}} *</span>
                                <span v-if="selectedList.id_actionable_list" class="selected">{{selectedList.name}}</span>
                                <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" v-if="listDropdownToggled">
                                    <ul>
                                        <li v-for="recording in recordingsList" v-on:click="selectRecording(recording)" :key="recording.id_actionable_list">
                                            <span>{{recording.name}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col">
                            <h2 style="margin-bottom: 15px;">2. {{profile.actionable.add_to_list}}</h2>
                            <p style="margin-bottom: 32px; font-size: 16px;">{{profile.actionable.add_to_list_text}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6" style="border-right: 1px solid #ccc;">
                            <h3 style="font-size: 18px;">{{profile.actionable.choose_objects}} ({{ assets.length }})</h3>
                            <hr />
                            <section class="search search-inpage" :class="{active: searchActive}">
                                <form v-on:submit.prevent="search()"> 
                                    <input type="text" data-testid="searchObject" ref="search" v-bind:placeholder="profile.actionable.search_street" @input="e => searchTerm = e.target.value" :value="searchTerm" @keydown="search($event)"  />
                                    <span class="close-suggestions" @click="clearSearch()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>  
                            <div class="row" v-if="searchActive">
                                <div class="col">
                                    <h4 class="result-counter" v-if="!assets.length" style="margin-bottom: 20px;">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                                    <h4 class="result-counter" v-if="assets.length >= 1" style="margin-bottom: 20px;">{{assets.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="filter-checkbox-block" style="float: left;">
                                        <div class="checkbox-indicator" data-testid="withoutRecordingList" @click="selectFilter()">
                                            <i class="far fa-check-square" v-if="filter"></i>
                                            <i class="far fa-square" v-else></i>
                                        </div>
                                        <label @click="selectFilter()">{{profile.actionable.without_recording_list}} <span v-if="filter" style="font-weight: 500;">({{ filterCounter }})</span></label>
                                    </div>
                                    <div class="asset-checkbox-block">
                                        <div class="checkbox-indicator" data-testid="selectAllAssets" @click="selectAllAssets()" style="margin: 0 3px 0 0; float: right;">
                                            <i class="far fa-check-square" v-if="allAssetsSelected"></i>
                                            <i class="far fa-square" v-else></i>
                                        </div>
                                        <span style="float: right; font-weight: 500; margin: 3px 8px 0 0;">{{profile.actionable.select_all}}</span>
                                    </div>
                                </div>
                            </div>        
                            <div class="asset-blocks">
                                <draggable class="asset-list" :list="assets" :group="{name: 'assets', pull: 'clone', put: false}" @start="onDragStart($event)" @end="onDragEnd">
                                    <div class="asset-block recording no-hover" data-testid="possibleAsset" v-for="asset in assets" :key="asset.id_asset" style="width: 100%; cursor: pointer; background: #f9f9f9;" @click="selectAsset(asset)" :class="{selected: asset.selected, added: asset.lists.length}" :data-asset-id="asset.id_asset" v-show="!asset.hidden">
                                        <h4 style="margin-bottom: 0;">{{asset.name}}</h4>
                                        <span class="placename" v-if="asset.place_name" style="height: auto; margin-top: 0;">{{asset.place_name}}</span>
                                        <p class="location" style="margin-bottom: 0;"><i class="fas fa-map-marker-alt"></i> {{asset.place_address}} {{asset.place_house_number}}, {{asset.place_zipcode}} {{asset.place_city}}</p>
                                        <div class="added-labels">
                                            <span v-for="(list, index) in asset.lists" :key="index">{{ list }}</span>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                        </div>
                        <div class="col-sm-6">                         
                            <h3 style="font-size: 18px;">{{profile.actionable.chosen_objects}}
                                <span class="edit-button icon" data-testid="addExtraList" @click="addNewList = !addNewList" style="position: relative; float: right; z-index: 3; margin-right: -10px;"><i class="fas fa-plus" style="margin: -3px -5px 0 0;"></i></span>
                            </h3>
                            <div class="new-list" v-if="addNewList">
                                <h3>{{profile.actionable.add_recording_list}}</h3>
                                <div class="dropdown-section" v-click-outside="closeListDropdown">
                                    <span class="dropdown" v-on:click="listDropdownToggled = !listDropdownToggled" :class="{active: listDropdownToggled}">
                                    <span v-if="!selectedList.id_actionable_list">{{profile.actionable.recordingslist}} *</span>
                                    <span v-if="selectedList.id_actionable_list" class="selected">{{selectedList.name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                    <div class="dropdown-list" v-if="listDropdownToggled">
                                        <ul>
                                            <li v-for="recording in recordingsList" v-on:click="selectRecording(recording)" :key="recording.id_actionable_list">
                                                <span>{{recording.name}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="recording-list" v-for="list in selectedLists" :key="list.id_actionable_list" :class="{toggled: list.toggled, empty: !list.assets.length}">
                                <div class="toggle-area" @click.stop="list.toggled = !list.toggled">
                                    <h4>{{list.name}} ({{ list.assets.length }})</h4> 
                                    <i class="fas fa-chevron-down chevron" style="float: right; font-size: 21px; color: #0867b3; margin-left: 10px;" v-if="list.assets.length"></i>
                                    <span class="remove" data-testid="removeChosenList" @click="removeListSelection(list)" v-if="!list.assets.length"><i class="far fa-trash-alt"></i></span>      
                                </div>
                                <div class="asset-blocks">
                                    <draggable class="asset-list" data-testid="chosenAssetList" :group="{ name: 'assets', pull: false, put: true }" :list="list.assets" @start="dragging = true" @end="dragging = false" @add="event => onDrop(event, list)">
                                        <div class="drag-box" v-if="!list.assets.length" @click="addManual(list)">{{profile.actionable.drag_object}}</div>
                                        <div class="asset-block recording no-hover" v-for="asset in list.assets" :key="asset.id_asset" style="width: 100%;" v-show="list.toggled">
                                            <h4 style="margin-bottom: 0;">{{asset.name}}</h4>
                                            <span class="placename" v-if="asset.place_name" style="height: auto; margin-top: 0;">{{asset.place_name}}</span>
                                            <p class="location" style="margin-bottom: 0;"><i class="fas fa-map-marker-alt"></i> {{asset.place_address}} {{asset.place_house_number}}, {{asset.place_zipcode}} {{asset.place_city}}</p>
                                            <span class="remove" data-testid="removeChosenAsset" @click="removeFromSelection(list, asset)"><i class="far fa-trash-alt"></i></span>      
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div class="button button--icon" style="float: right;" @click="collectAssets()" v-if="industry === 'security'"><i class="fas fa-route"></i> {{profile.actionable.define_route}}</div>          
                            <div class="button button--icon" data-testid="chooseMoment" style="float: right; margin-top: 20px;" @click="collectAssets(true)" v-else><i class="fas fa-calendar-plus"></i> {{profile.actionable.choose_moment}}</div>
                        </div>
                    </div>      
                </div>       
            </div>
        </div>
        <div v-if="collectedAssets.length && !selectDate && industry === 'security'">
            <h2 style="clear: both;">3. {{profile.actionable.define_route}} <i class="fas fa-info-circle" v-tooltip.right="'Sleep een object of verander het nummer om de volgorde te wijzigen'" style="cursor: pointer; font-size: 15px;"></i></h2>
            <div class="asset-blocks">
                <draggable class="asset-list" :list="collectedAssets" @start="dragging = true" @end="endChangeOrder()">
                    <div class="block-wrap" v-for="(asset, index) in collectedAssets" :key="asset.id_asset">
                        <input type="text" class="form-control order" :value="index + 1" @input="changeOrder($event, index)">
                        <div class="asset-block route no-hover" style="width:90%; float: left;">
                            <span class="new" v-if="asset.new">{{profile.actionable.newly_added}}</span>
                            <h4 style="margin-bottom: 0;">{{asset.name}}</h4>
                            <span class="placename" v-if="asset.place_name" style="height: auto; margin-top: 0;">{{asset.place_name}}</span>
                            <p class="location" style="margin-bottom: 0;"><i class="fas fa-map-marker-alt"></i> {{asset.place_address}} {{asset.place_house_number}}, {{asset.place_zipcode}} {{asset.place_city}}</p>
                            <div class="added-labels">
                                <span v-for="(list, index) in asset.lists" :key="index">{{ list }}</span>
                            </div>
                        </div>
                    </div>    
                </draggable>    
            </div>
            <div class="button button--icon" style="float: right; margin-top: 20px;" @click="selectDate = true"><i class="fas fa-calendar-plus"></i> {{profile.actionable.choose_moment}}</div>
        </div>
        <div v-show="selectDate">
            <a class="back-link" @click="back()" style="margin-bottom: 10px;"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
            <h2 style="clear: both;"><span v-if="industry === 'security'" style="font-weight: 500;">4.</span><span style="font-weight: 500;" v-else>3.</span> {{profile.actionable.choose_a_moment}}</h2>
            <div class="row">
                <div class="col">
                    <form v-show="collectedAssets.length">
                        <div class="row">
                            <div class="col">
                                <label>{{profile.actionable.latest_recording_date}} <i class="fas fa-info-circle" v-tooltip.right="profile.actionable.choose_dates_tooltip" style="cursor: pointer;"></i></label>
                                <flat-pickr v-model="allLatestDate" class="form-control" :config="flatPickrDateConfig" :placeholder="profile.actionable.choose_dates" data-testid="chooseDate"  @on-change="changeDates"></flat-pickr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label>{{profile.incident_detail.mechanic_comment}}</label>
                                <div class="dropdown-section" v-if="mechanics.length">
                                    <span class="dropdown" data-testid="specialistSelect" v-on:click="allMechanicDropdownToggled = !allMechanicDropdownToggled" :class="{active: allMechanicDropdownToggled}">
                                        <span v-if="!allSelectedMechanic.id_mechanic">{{profile.incident_detail.select_a_mechanic}} *</span>
                                        <span v-if="allSelectedMechanic.id_mechanic" class="selected">{{allSelectedMechanic.name}}</span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </span>
                                    <div class="dropdown-list" v-if="allMechanicDropdownToggled">
                                        <ul>
                                            <li v-for="mechanic in mechanics" v-on:click="selectMechanicAll(mechanic)" :key="mechanic.id_mechanic">
                                                <span>{{mechanic.name}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col">
                                        <div class="notification notification--error">{{profile.accountPage.account.create_service}}.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="button button--icon" data-testid="submitRecording" style="margin-top: 15px; float: right;" @click="saveAllItems()" v-if="industry === 'security'"><i class="fas fa-save"></i> {{profile.actionable.save_route}}</span>
                        <span class="button button--icon" data-testid="submitRecording" style="margin-top: 15px; float: right;" @click="saveAllItems()" v-else><i class="fas fa-save"></i> {{profile.actionable.save_recording}}</span>
                    </form>
                </div>
            </div>
        </div>     
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import debounce from 'lodash/debounce';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import uniqBy from 'lodash/uniqBy';
import draggable from 'vuedraggable';
import sortBy from 'lodash/sortBy';
export default {
    mixins: [setLocale],
    components: {
        flatPickr,
        draggable
    },
    props: ['recordings', 'mechanics'],
    data() {
        return {
            profile: {},
            searchTerm: '',
            searchActive: false,
            assets: [],
            loading: false,
            selectedAssets: [],
            latestDate: null,
            selectedList: {},
            listDropdownToggled: false,
            flatPickrDateConfig: {
                altInput: true,
                dateFormat: "Y-m-d",
                mode: "multiple"
            },
            mechanicDropdownToggled: false,
            selectedMechanic: {},
            allSelectedList: {},
            allLatestDate: null,
            allSelectedMechanic: {},
            allListDropdownToggled: false,
            allMechanicDropdownToggled: false,
            recordingsList: [],
            allAssetsSelected: false,
            selectedLists: [],
            collectedAssets: [],
            selectedDraggableAssets: [],
            selectDate: false,
            addNewList: false,
            filter: false,
            filterCounter: 0,
            dragging: false,
            planningSaved: false
        }
    },
    created() {      
        //this.industry = 'security';
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        let today = new Date();
        today.setDate(today.getDate());
        today = moment(today).format();
        today = today.split('T')[0];
        this.flatPickrDateConfig.minDate = today;
        this.recordingsList = this.recordings;
        this.recordingsList = sortBy(this.recordingsList, 'name');
        this.getAssets();
    },
    methods: {
        search($event) {
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.filter = false;
                this.searchActive = true;
                this.getAssets();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.getAssets();
            }
            if ($event) {
                if($event.keyCode === 27) {
                    this.searchActive = false;
                    this.searchTerm = '';
                    this.getAssets();
                }
            }   
        },
        clearSearch() {
            this.assets = [];
            this.searchActive = false;
            this.searchTerm = '';
            this.getAssets();
        },
        getAssets: debounce(function (e) {
            this.loading = true;
            this.assets = [];
            let searchParam = '';
            if (this.searchTerm) {
                searchParam = '&search_name=' + this.searchTerm;
            }
            let self = this;
            fetch('/api/v1/asset/filtered?limit=250&includeNonActive=false&includeNoQr=false' + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.selected = false;
                        item.edit = false;
                        item.hidden = false;
                        item.lists = [];
                        item.new = false;
                        item.order = null;
                        if (self.selectedAssets.length) {
                            for (var j = 0; j < self.selectedAssets.length; j++) {
                                let selectedAsset = self.selectedAssets[j];
                                if (item.id_asset === selectedAsset.id_asset) {
                                    item.selected = true;
                                }
                            }
                        }
                        self.assets.push(item);
                    }
                    self.loading = false;
                });
            });
        }, 500),
        selectFilter(keep) {
            if (!keep) {
                this.filter = !this.filter;
            }
            if (this.filter) {
                this.filterCounter = 0;
                for (var i = this.assets.length - 1; i >= 0; i--) {
                    let assetItem = this.assets[i];
                    if (assetItem.lists.length) {
                        assetItem.hidden = true;
                    }
                    else {
                        this.filterCounter++;
                    }
                }
            }
            else {
                this.filterCounter = 0;
                for (var i = this.assets.length - 1; i >= 0; i--) {
                    let assetItem = this.assets[i];
                    assetItem.hidden = false;
                }
            }
        },
        selectAsset(asset) {
            if (this.dragging) return;
            asset.selected = !asset.selected;
            if (asset.selected) {
                this.selectedAssets.push(asset);
            }
            else {
                for (var i = 0; i < this.selectedAssets.length; i++) {
                    let assetItem = this.selectedAssets[i];
                    if (assetItem.id_asset === asset.id_asset) {
                        this.selectedAssets.splice(i, 1);
                    }
                }
            }
        },
        selectAllAssets() {
            this.allAssetsSelected = !this.allAssetsSelected
            if (this.allAssetsSelected) {
                for (var i = 0; i < this.assets.length; i++) {
                    let assetItem = this.assets[i];
                    assetItem.selected = true;
                    this.selectedAssets.push(assetItem);
                }
            }
            else {
                for (var i = 0; i < this.assets.length; i++) {
                    let assetItem = this.assets[i];
                    assetItem.selected = false;
                }
                this.selectedAssets = [];
            }
        },
        onDragStart(evt) {
            this.dragging = true;
            const assetId = evt.item.dataset.assetId; // Assuming you have a data attribute like `data-asset-id` on your HTML element
            const asset = this.assets.find(asset => asset.id_asset.toString() === assetId);
            // Clone the selected assets for dragging
            this.selectedDraggableAssets = this.assets.filter(a => a.selected).map(a => ({ ...a }));
            if (this.selectedDraggableAssets.length) {
                evt.items = this.selectedDraggableAssets;
            } else {
                // If only one asset is dragged, mark it as selected
                asset.selected = true;
            }
        },
        onDragEnd() {
            this.dragging = false;
        },
        onDrop(evt, list) {
            list.toggled = true;
            this.selectedDraggableAssets.forEach(asset => {
                this.addNewAssets(list.assets, asset);
            });
            list.assets = uniqBy(list.assets, 'id_asset');
            for (var i = 0; i < this.assets.length; i++) {
                let assetItem = this.assets[i];
                if (assetItem.selected) {
                    assetItem.lists.push(list.name);
                    assetItem.selected = false;
                }
                assetItem.lists = uniqBy(assetItem.lists);
            }
            this.allAssetsSelected = false;
            this.selectedAssets = [];
            this.selectFilter(true);
        },
        addManual(list) {
            this.selectedDraggableAssets = this.assets.filter(a => a.selected).map(a => ({ ...a }));
            list.toggled = true;
            this.selectedDraggableAssets.forEach(asset => {
                this.addNewAssets(list.assets, asset);
            });
            list.assets = uniqBy(list.assets, 'id_asset');
            for (var i = 0; i < this.assets.length; i++) {
                let assetItem = this.assets[i];
                if (assetItem.selected) {
                    assetItem.lists.push(list.name);
                    assetItem.selected = false;
                }
                assetItem.lists = uniqBy(assetItem.lists);
            }
            this.allAssetsSelected = false;
            this.selectedAssets = [];
            this.selectFilter(true);
        },
        addSelectionFromDrag(event, list) {
            const draggedItems = event.items;
            draggedItems.forEach(item => {
                item.selected = false;
            });
            list.assets.push(...draggedItems);
            for (var i = 0; i < this.assets.length; i++) {
                let assetItem = this.assets[i];
                assetItem.selected = false;
                assetItem.lists = uniqBy(assetItem.lists);
            }
            this.selectFilter(true);
        },
        removeListSelection(list) {
            for (var i = this.selectedLists.length - 1; i >= 0; i--) {
                let listItem = this.selectedLists[i];
                if (listItem.id_actionable_list === list.id_actionable_list) {
                    this.selectedLists.splice(i, 1);
                }
            }
            this.recordingsList.push(list);
            this.recordingsList = sortBy(this.recordingsList, 'name');
        },
        addNewAssets(arr, obj) {
            const exists = arr.some(item => item.id_asset === obj.id_asset);    
            if (!exists) {
                arr.push(obj);
            }
        },
        removeFromSelection(list, asset) {
            for (var i = list.assets.length - 1; i >= 0; i--) {
                let assetItem = list.assets[i];
                if (assetItem.id_asset === asset.id_asset) {
                    list.assets.splice(i, 1);
                }
            }
            for (var i = this.assets.length - 1; i >= 0; i--) {
                let assetItem = this.assets[i];
                if (assetItem.id_asset === asset.id_asset) {
                    if (assetItem.lists.length) {
                        for (var j = assetItem.lists.length - 1; j >= 0; j--) {
                            let listItem = assetItem.lists[j];
                            if (listItem === list.name) {
                                assetItem.lists.splice(j, 1);
                            }  
                        }
                    }
                }     
            }
            this.selectFilter(true);
        }, 
        removeSelectedAsset(asset) {
            for (var i = 0; i < this.selectedAssets.length; i++) {
                let assetItem = this.selectedAssets[i];
                if (assetItem.id_asset === asset.id_asset) {
                    this.selectedAssets.splice(i, 1);
                }
            }
            for (var i = 0; i < this.assets.length; i++) {
                let assetItem = this.assets[i];
                if (assetItem.id_asset === asset.id_asset) {
                    assetItem.selected = false;
                }
            }
        },
        selectRecording(recording) {
            this.planningSaved = false;
            this.addNewList = false;
            this.listDropdownToggled = false;
            this.selectedLists.push(recording);
            for (var i = 0; i < this.recordingsList.length; i++) {
                let listItem = this.recordingsList[i];
                if (listItem.id_actionable_list === recording.id_actionable_list) {
                    this.recordingsList.splice(i, 1);
                }
            }
        },
        collectAssets(selectDate) {
            for (var i = 0; i < this.selectedLists.length; i++) {
                let list = this.selectedLists[i];
                for (var j = 0; j < list.assets.length; j++) {
                    let asset = list.assets[j];
                    asset.list_id = list.id_actionable_list;
                    if (!asset.order) {
                        asset.new = true;
                    }
                    else {
                        asset.new = false;
                    }
                    this.collectedAssets.push(asset);
                }
            }
            const newAssets = this.collectedAssets.filter(asset => asset.new);
            const oldAssets = this.collectedAssets.filter(asset => !asset.new);
            const reorderedAssets = [...newAssets, ...oldAssets];
            this.collectedAssets = reorderedAssets;
            if (selectDate) {
                this.selectDate = true;
            }
        },
        back() {
            this.selectDate = false;
            this.collectedAssets = [];
        },
        rememberOrder() {
            for (var i = 0; i < this.collectedAssets.length; i++) {
                let collectedAsset = this.collectedAssets[i];
                collectedAsset.order = i + 1;
            }
            // Iterate over outerAssetsArray to update the order in otherAssets
            for (let list of this.selectedLists) {
                if (Array.isArray(list.selectedLists)) {
                    for (let asset of list.assets) {
                        // Find corresponding asset in collectedAssets
                        let correspondingAsset = this.collectedAssets.find(collectedAsset => collectedAsset.id === asset.id);
                        if (correspondingAsset) {
                            asset.order = correspondingAsset.order;
                        }
                    }
                }
            }
            this.collectedAssets = [];
        },
        changeOrder(event, currentIndex) {
            // Retrieve the new order value and ensure it's a number
            let newOrder = parseInt(event.target.value, 10); // Convert to integer

            // Check if the new order is a valid number and within the bounds of collectedAssets
            if (!isNaN(newOrder) && newOrder > 0 && newOrder <= this.collectedAssets.length) {
                // Adjust to 0-based index
                newOrder -= 1;
                // Move the item to the new index
                this.collectedAssets = this.moveItem(this.collectedAssets, currentIndex, newOrder);
                this.$vToastify.success(this.profile.toast.edited, this.profile.toast.changes_saved);
            } else {
                // Handle invalid input (e.g., set to last available position or show an error)
                console.warn("Invalid order index");
            }
        },
        endChangeOrder() {
            this.dragging = false;
            this.$vToastify.success('De volgorde is succesvol aangepast.', 'Volgorde aangepast');
        },
        moveItem(array, fromIndex, toIndex) {
            // Ensure indices are within array bounds
            if (fromIndex < 0 || fromIndex >= array.length) {
                return array;
            }
            // Remove the item from its current position
            const item = array.splice(fromIndex, 1)[0];
            // Insert the item at the new position
            array.splice(toIndex, 0, item);
            return array;
        },
        selectMechanic(mechanic) {
            this.mechanicDropdownToggled = false;
            this.selectedMechanic = mechanic;
        },
        selectMechanicAll(mechanic) {
            this.allMechanicDropdownToggled = false;
            this.allSelectedMechanic = mechanic;
        },
        saveAllItems() {
            let allDates = this.allLatestDate.split(', ');
            let counter = this.collectedAssets.length * allDates.length;
            for (var i = 0; i < allDates.length; i++) {
                let dateEntry = allDates[i];
                let date = new Date(dateEntry);
                date.setHours(23, 59, 59);
                date = date.getTime();
                for (var j = 0; j < this.collectedAssets.length; j++) {
                    let collectedAsset = this.collectedAssets[j];
                    const data = new URLSearchParams();
                    data.append('id_actionable_list', collectedAsset.list_id);
                    data.append('id_asset', collectedAsset.id_asset);
                    data.append('latest_possible', date);
                    data.append('id_mechanic', this.allSelectedMechanic.id_mechanic);
                    if (this.industry === 'security') {
                        data.append('list_order', j + 1);
                    }
                    let self = this;
                    fetch('/api/v1/plannedactionlist/create', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        self.$gtag.event('click', {
                            event_category: 'Button',
                            event_label: 'Opname inplannen',
                        });
                        counter--;
                        if (!counter) {
                            self.searchTerm = '';
                            self.searchActive = false;
                            self.collectedAssets = [];
                            self.selectedLists = [];
                            self.selectDate = false;
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                            self.planningSaved = true;
                            self.allLatestDate = null;
                            self.getAssets();
                        }
                    });
                }
            }
        },
        changeDates(selectedDates, dateStr, instance) {
            let dateValues = [];
            for (var i = 0; i < selectedDates.length; i++) {
                let date = selectedDates[i];
                let dateValue = moment(date).locale('nl').format('D MMM YYYY');
                dateValues.push(dateValue);
            }
            let dateValuesString = dateValues.join(', ');
            instance.altInput.value = dateValuesString;
        },
        closeListDropdown() {
            this.listDropdownToggled = false;
        },
        closeMechanicDropdown() {
            this.mechanicDropdownToggled = false;
        }
    }
}
</script>
