<template>
    <div class="block block--owner_detail">
        <div class="incident-head">
            <h1 style="max-width: 100%;">{{profile.owners.agreementflow.agreement_flow}}</h1>
        </div>
        <div class="incident-contents">
            <div class="incident-info">
                <div class="row">
                    <div class="col-md-10 col-lg-8">
                        <label>{{profile.owners.agreementflow.type_agreement}}</label>
                        <div class="dropdown-section" style="margin: 0;">
                            <span class="dropdown" data-testid="agreementTypeSelect" v-on:click="agreementDropdownToggled = !agreementDropdownToggled" :class="{active: agreementDropdownToggled}">
                                <span data-testid="agreementTypeSelect" style="color: #212529; font-size: 14px;" v-if="owner.agreement_flow === 'NO_FLOW'">{{profile.owners.agreementflow.no_flow}}</span>
                                <span data-testid="agreementTypeSelect" style="color: #212529; font-size: 14px;" v-if="owner.agreement_flow === 'ALWAYS_FLOW' && vve">{{profile.owners.agreementflow.contactinfo_vve}}</span>
                                <span data-testid="agreementTypeSelect" style="color: #212529; font-size: 14px;" v-if="owner.agreement_flow === 'ALWAYS_FLOW' && !vve">{{profile.owners.agreementflow.contactinfo_owner}}</span>
                                <span data-testid="agreementTypeSelect" style="color: #212529; font-size: 14px;" v-if="owner.agreement_flow === 'FLOW_ON_CATEGORY'">{{profile.owners.agreementflow.per_category}}</span>
                                <i class="fas fa-chevron-down chevron"></i></span>
                            <div class="dropdown-list" v-if="agreementDropdownToggled">
                                <ul>
                                    <li data-testid="agreementTypeResult" @click="saveFlow('NO_FLOW')">{{profile.owners.agreementflow.no_flow}}</li>
                                    <li data-testid="agreementTypeResult" @click="saveFlow('ALWAYS_FLOW')" v-if="vve">{{profile.owners.agreementflow.contactinfo_vve}}</li>
                                    <li data-testid="agreementTypeResult" @click="saveFlow('ALWAYS_FLOW')" v-else>{{profile.owners.agreementflow.contactinfo_owner}}</li>
                                    <li data-testid="agreementTypeResult" @click="saveFlow('FLOW_ON_CATEGORY')">{{profile.owners.agreementflow.per_category}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr v-if="owner.agreement_flow === 'FLOW_ON_CATEGORY'" />
                <div class="row">
                    <div class="col">
                        <h3 style="margin-bottom: 0; float: left;" v-if="owner.agreement_flow === 'FLOW_ON_CATEGORY'">{{profile.assets.categories}}</h3>
                        <span class="edit-button icon" data-testid="agreementCategoryAdd" @click="addNewCategory = !addNewCategory"  v-if="owner.agreement_flow === 'FLOW_ON_CATEGORY' && !disableEdit" style="float: right; position: relative; margin-top: 0; top: 0; right: 0;"  :class="{active: addNewCategory}"><i class="fas fa-plus"></i></span>
                    </div>
                </div>
                <div class="row" v-if="owner.agreement_flow === 'FLOW_ON_CATEGORY' && addNewCategory" style="margin-top: 10px;">
                    <div class="col-sm-6">
                        <label>{{profile.incident_detail.category}}</label>
                        <div class="dropdown-section">
                            <span class="dropdown" data-testid="agreementCategorySelect" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled, error:categoryError}">
                                <span data-testid="agreementCategorySelect" v-if="!newCategoryfields.selectedCategory.id_incident_category">{{profile.incident_detail.select_category}}</span>
                                <span data-testid="agreementCategorySelect" v-if="newCategoryfields.selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{newCategoryfields.selectedCategory.dutch_name}}</span>
                                <span data-testid="agreementCategorySelect" v-if="newCategoryfields.selectedCategory.id_incident_category && locale === 'en'" class="selected">{{newCategoryfields.selectedCategory.english_name}}</span>
                                <i class="fas fa-chevron-down chevron"></i></span>
                            <div class="dropdown-list" v-if="categoryDropdownToggled">
                                <ul>
                                    <li v-for="(category, index) in categories" v-on:click="selectCategory(category)" :key="index">
                                        <span data-testid="agreementCategoryResult" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                        <span data-testid="agreementCategoryResult" v-else>{{category.english_name}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label>{{profile.accountPage.account.contactname}}</label>
                        <div class="dropdown-section" v-if="contacts.length">
                            <span class="dropdown" v-on:click="contactPersonDropdownToggled = !contactPersonDropdownToggled" :class="{active: contactPersonDropdownToggled, error: contactError}">
                                <span v-if="!selectedContact.name">{{ profile.owner.choose_contact_person }} *</span>
                                <span v-if="selectedContact.name" class="selected">{{selectedContact.name}}</span>
                                <i class="fas fa-chevron-down chevron"></i></span>
                            <div class="dropdown-list" v-if="contactPersonDropdownToggled">
                                <ul>
                                    <li v-for="contact in contacts" v-on:click="selectContact(contact)" :key="contact.id_contact_person">
                                        {{contact.name}}
                                    </li>
                                </ul>
                            </div>
                        </div>    
                        <p v-else><span style="display: block;">{{ profile.owners.contacts.no_contacts }}</span><span class="text-link" style="display: block; margin: 0;" @click="newContactModalOpen = true">{{ profile.owners.contacts.add_contact }}</span></p>                
                    </div>
                    <div class="col-sm-6">
                        <label>{{profile.incidents.new_incident.emailaddress}}</label>
                        <input data-testid="agreementEmailInput" type="email" class="form-control" v-model="newCategoryfields.email" :class="{valid: validEmail, error: invalidEmail}" @input="validateEmail" />
                    </div>
                    <div class="col-sm-6">
                    <label>{{profile.accountPage.account.contactmobile}} <i class="fas fa-info-circle" v-tooltip.right="profile.owners.agreementflow.phoneAndEmailTooltip"></i></label>
                        <vue-tel-input v-model="newCategoryfields.phone" class="form-control" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone"></vue-tel-input>
                    </div>
                </div>
                <button class="button" data-testid="agreementSubmitButton" v-if="owner.agreement_flow === 'FLOW_ON_CATEGORY' && addNewCategory" style="margin: 10px 0; float: right;" @click="saveNewCategory()">Categorie opslaan</button>
                <div class="agreement" v-show="owner.agreement_flow === 'FLOW_ON_CATEGORY'" v-for="agreement in personAgreements" :key="agreement.id_agreement_flow_person" :class="{active: agreement.agreementEdit}">
                    <span class="edit-button edit-button--small" data-testid="agreementSaveButton" @click="saveAgreement()" v-if="agreement.agreementEdit" style="margin-right: 20px;"><i class="fas fa-save" data-testid='personalSaveButton'></i></span>
                    <span class="edit-button edit-button--small" data-testid="agreementEditButton" @click="selectAgreement(agreement)" v-if="!agreement.agreementEdit" style="margin-right: 20px;"><i class="fas fa-pen"></i></span>
                    <span class="edit-button edit-button--small" data-testid="agreementRemoveButton" @click="removeAgreement(agreement)"><i class="fas fa-trash-alt"></i></span>
                    <div class="row" v-if="agreement.agreementEdit">
                        <div class="col-sm-6">
                            <label>{{profile.incident_detail.category}}</label>
                            <div class="dropdown-section">
                                <span class="dropdown" data-testid="agreementCategoryEdit" v-on:click="agreement.categoryDropdownToggled = !agreement.categoryDropdownToggled" :class="{active: agreement.categoryDropdownToggled}">
                                    <span data-testid="agreementCategoryEdit" v-if="locale === 'nl'" class="selected">{{agreement.dutch_name}}</span>
                                    <span data-testid="agreementCategoryEdit" v-else class="selected">{{agreement.english_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" v-if="agreement.categoryDropdownToggled">
                                    <ul>
                                        <li v-for="(category, index) in categories" v-on:click="selectCategory(category, agreement)" :key="index">
                                            <span data-testid="agreementCategoryEditResult" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span data-testid="agreementCategoryEditResult" v-else>{{category.english_name}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactname}}</label>
                            <div class="dropdown-section">
                                <span class="dropdown" v-on:click="contactPersonDropdownToggled = !contactPersonDropdownToggled" :class="{active: contactPersonDropdownToggled, error: contactError}">
                                    <span v-if="!selectedContact.name">{{ profile.owner.choose_contact_person }} *</span>
                                    <span v-if="selectedContact.name" class="selected">{{selectedContact.name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" v-if="contactPersonDropdownToggled">
                                    <ul>
                                        <li v-for="contact in contacts" v-on:click="selectContact(contact)" :key="contact.id_contact_person">
                                            {{contact.name}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.incidents.new_incident.emailaddress}}</label>
                            <input data-testid="agreementEmailEdit" type="email" class="form-control" v-model="selectedAgreement.email" :class="{valid: agreement.validEmail, error: agreement.invalidEmail}" @input="validateEmail(agreement)" />
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactmobile}} <i class="fas fa-info-circle" v-tooltip.right="profile.owners.agreementflow.phoneAndEmailTooltip"></i></label>
                            <vue-tel-input v-model="selectedAgreement.mobile" class="form-control" v-bind="bindProps" style="padding-left: 0;" :class="{valid: selectedAgreement.validPhone, error: selectedAgreement.invalidPhone}" @input="validatePhone"></vue-tel-input>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-sm-6">
                            <label>{{profile.incident_detail.category}}</label>
                            <p data-testid="agreementCategoryText" v-if="locale === 'nl'">{{agreement.dutch_name}}</p>
                            <p data-testid="agreementCategoryText" v-else>{{agreement.english_name}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactname}}</label>
                            <p data-testid="agreementNameText">{{agreement.name}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.incidents.new_incident.emailaddress}}</label>
                            <p data-testid="agreementEmailText">{{agreement.email}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactmobile}}</label>
                            <p data-testid="agreementPhoneText">{{agreement.mobile}}</p>
                        </div>
                    </div>
                </div>
                <div class="notification notification--error" v-if="alreadyExistsError" style="margin-top: 10px;">{{profile.owners.agreementflow.already_exists}}</div>
            </div>
        </div>
        <NewContactModal v-if="newContactModalOpen" @close="newContactModalOpen = false" :contacts="contacts" :vve="vve" :ownerId="owner.id_owner" @success="getContacts()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { VueTelInput } from 'vue-tel-input';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import NewContactModal from "@/components/modals/NewContactModal.vue";

export default {
    name: 'Agreementflow',
    mixins: [setLocale],
    components: {
        NewContactModal,
        VueTelInput
    },
    data: function () {
        return {
            newContactModalOpen: false,
            agreementDropdownToggled: false,
            categoryDropdownToggled: false,
            repairCompanyDropdownToggled: false,
            selectedRepairCompany: {},
            addNewCategory: false,
            newCategoryfields: {
                selectedCategory: {},
                name: '',
                email: '',
                phone: ''
            },
            validPhone: false,
            invalidPhone: false,
            validEmail: false,
            invalidEmail: false,
            categoryError: false,
            personAgreements: [],
            selectedAgreement: {},
            countryCode: '',
            formattedPhone: '',
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder:'',
                }
            },
            alreadyExistsError: false,
            contacts: [],
            contactPersonDropdownToggled: false,
            selectedContact: {},
            contactError: false
        }
    },
    computed: mapState(['vve']),
    props: ['owner', 'categories', 'repaircompanies', 'disableEdit'],
    mounted() {
        this.$parent.$on('getContacts', this.getContacts);
    },
    beforeDestroy() {
        this.$parent.$off('getContacts', this.getContacts);
    },
    created() {
        this.getPersonAgreements();
        this.getContacts();
    },
    methods: {
        getPersonAgreements() {
            this.personAgreements = [];
            let self = this;
            fetch('/api/v1/agreement_flow_person/byidowner?id_owner=' + this.owner.id_owner)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let agreement = data.data[i];
                        agreement.agreementEdit = false;
                        agreement.validPhone = false;
                        agreement.contactError = false;
                        agreement.invalidPhone = false;
                        agreement.validEmail = false;
                        agreement.invalidEmail = false;
                        agreement.categoryDropdownToggled = false;
                        self.personAgreements.push(agreement);
                    }
                });
            });
        },
        getContacts(tab) { 
            if (tab === 'agreementflow' || !tab) {
                this.newContactModalOpen = false;
                let self = this;
                fetch('/api/v1/contactperson/byidowner?id_owner=' + this.owner.id_owner)
                .then(response => { response.json().then(
                    function(data) {
                        let contactsWithAccount = [];
                        let contactsWithOutAccount = [];
                        for (var i = 0; i < data.length; i++) {
                            let contact = data[i];
                            if (contact.id_shiro_user) {
                                contactsWithAccount.push(contact); 
                            }
                            else {
                                contactsWithOutAccount.push(contact); 
                            }
                        }
                        let idsToRemove = contactsWithAccount.map(obj => obj.email);
                        
                        for (let i = contactsWithOutAccount.length - 1; i >= 0; i--) {
                            if (idsToRemove.indexOf(contactsWithOutAccount[i].email) !== -1) {
                                contactsWithOutAccount.splice(i, 1);
                            }
                        }
                        self.contacts = contactsWithAccount.concat(contactsWithOutAccount);
                        self.contacts = sortBy(self.contacts, 'name');
                        if (self.contacts.length === 1) {
                            self.selectContact(self.contacts[0]);
                        }
                    });
                });
            }      
        },
        selectContact(contact) {
            this.selectedContact = contact;
            this.contactPersonDropdownToggled = false;
            this.contactError = false;
            this.newCategoryfields.email = contact.email;
            this.newCategoryfields.phone = contact.mobile;
            this.selectedAgreement.email = contact.email;
            this.selectedAgreement.mobile = contact.mobile;
            this.validateEmail();
        },  
        saveFlow(type) {
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.owner.id_owner + '][agreement_flow]', type);
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.addNewCategory = false;
                self.agreementDropdownToggled = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('getOwner');
            });
        },
        selectCategory(category, agreement) {
            if (agreement) {
                agreement.categoryDropdownToggled = false;
                agreement.dutch_name = category.dutch_name;
                agreement.english_name = category.english_name;
                agreement.id_incident_category = category.id_incident_category;
            }
            else {
                this.categoryDropdownToggled = false;
                this.newCategoryfields.selectedCategory = category;
            }
            this.categoryError = false;
        },
        validatePhone: function (number, object) {
            if (this.selectedAgreement.id_agreement_flow_person) {
                if (this.selectedAgreement.mobile) {
                    this.countryCode = object.countryCode;
                    if (object.countryCode === undefined) {
                        object.countryCode = this.countryCode;
                    }
                    if (object.formatted) {
                        if (object.valid) {
                            this.selectedAgreement.validPhone = true;
                            this.selectedAgreement.invalidPhone = false;
                            this.formattedPhone = object.number;
                        }
                        else {
                            this.selectedAgreement.validPhone = false;
                            this.selectedAgreement.invalidPhone = true;
                        }
                    }
                }
                else {
                    this.selectedAgreement.validPhone = false;
                    this.selectedAgreement.invalidPhone = false;
                }
                if (this.selectedAgreement.validPhone && !this.selectedAgreement.email.length) {
                    this.selectedAgreement.invalidEmail = false;
                }
            }
            else {
                if (this.newCategoryfields.phone) {
                    if (object.formatted) {
                        if (object.valid) {
                            this.validPhone = true;
                            this.invalidPhone = false;
                            this.formattedPhone = object.number;
                        }
                        else {
                            this.validPhone = false;
                            this.invalidPhone = true;
                        }
                    }
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = false;
                }
                if (this.validPhone && !this.newCategoryfields.email.length) {
                    this.invalidEmail = false;
                }
            }
        },
        validateEmail() {
            if (this.selectedAgreement.id_agreement_flow_person) {
                if (this.selectedAgreement.email) {
                    let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                    if (pattern.test(this.selectedAgreement.email)) {
                        this.selectedAgreement.validEmail = true;
                        this.selectedAgreement.invalidEmail = false;
                    }
                    else {
                        this.selectedAgreement.invalidEmail = true;
                        this.selectedAgreement.validEmail = false;
                    }
                }
                else {
                    this.selectedAgreement.validEmail = false;
                    this.selectedAgreement.invalidEmail = false;
                }
                if (this.selectedAgreement.validEmail && !this.selectedAgreement.mobile.length) {
                    this.selectedAgreement.invalidPhone = false;
                }
            }
            else {
                if (this.newCategoryfields.email) {
                    let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                    if (pattern.test(this.newCategoryfields.email)) {
                        this.validEmail = true;
                        this.invalidEmail = false;
                    }
                    else {
                        this.invalidEmail = true;
                        this.validEmail = false;
                    }
                }
                else {
                    this.validEmail = false;
                    this.invalidEmail = false;
                }
                if (this.validEmail && !this.newCategoryfields.phone.length) {
                    this.invalidPhone = false;
                }
            }
        },
        saveNewCategory() {
            if (!this.newCategoryfields.selectedCategory.id_incident_category) {
                this.categoryError = true;
            }
            if (!this.selectedContact.name.length) {
                this.contactError = true;
            }
            if (!this.newCategoryfields.phone.length && !this.newCategoryfields.email.length) {
                this.invalidEmail = true;
                this.invalidPhone = true;
            }
            if (this.validEmail || this.validPhone && !this.contactError) {
                const data = new URLSearchParams();
                data.append('action', 'create');
                data.append('data[0][id_incident_category]', this.newCategoryfields.selectedCategory.id_incident_category);
                data.append('data[0][name]', this.selectedContact.name);
                data.append('data[0][email]', this.newCategoryfields.email);
                data.append('data[0][mobile]', this.formattedPhone);
                data.append('data[0][id_owner]', this.owner.id_owner);
                let self = this;
                fetch('/api/v1/agreement_flow_person', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    if (data.data[0].msg) {
                        self.alreadyExistsError = true;
                    }
                    else {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.getPersonAgreements();
                        self.addNewCategory = false;
                    }
                    self.newCategoryfields.name = '';
                    self.newCategoryfields.email = '';
                    self.newCategoryfields.phone = '';
                    self.selectedContact = {};
                    self.validEmail = false;
                    self.validPhone = false;
                    self.newCategoryfields.selectedCategory = {};
                    self.formattedPhone = '';
                });
            }
        },
        selectAgreement(agreement) {
            this.selectedAgreement = agreement;
            this.selectedAgreement.agreementEdit = true;
            this.selectedContact = agreement;
        },
        saveAgreement() {
            this.validateEmail(this.selectedAgreement);
            if (!this.selectedAgreement.mobile.length && !this.selectedAgreement.email.length) {
                this.selectedAgreement.invalidEmail = true;
                this.selectedAgreement.invalidPhone = true;
            }
            if (!this.selectedAgreement.mobile.length || !this.selectedAgreement.validPhone) {
                this.formattedPhone = '';
            }
            if ((this.selectedAgreement.validEmail || this.selectedAgreement.validPhone)) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.selectedAgreement.id_agreement_flow_person + '][id_incident_category]', this.selectedAgreement.id_incident_category);
                data.append('data[' + this.selectedAgreement.id_agreement_flow_person + '][id_owner]', this.owner.id_owner);
                data.append('data[' + this.selectedAgreement.id_agreement_flow_person + '][name]', this.selectedContact.name);
                data.append('data[' + this.selectedAgreement.id_agreement_flow_person + '][email]', this.selectedAgreement.email);
                data.append('data[' + this.selectedAgreement.id_agreement_flow_person + '][mobile]', this.formattedPhone);
                let self = this;
                fetch('/api/v1/agreement_flow_person', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Aanmaken regel accorderingsflow',
                    });
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.selectedAgreement = {};
                    self.selectedContact = {};
                    self.getPersonAgreements();
                });
            }
        },
        removeAgreement(agreement) {
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append('data[' + agreement.id_agreement_flow_person + '][id_agreement_flow_person]', agreement.id_agreement_flow_person);
            let self = this;
            fetch('/api/v1/agreement_flow_person', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getPersonAgreements();
            });
        },
        selectRepairCompany(repaircompany) {
            this.repairCompanyDropdownToggled = false;
            this.selectedRepairCompany = repaircompany;
        }
    }
}
</script>
